import {
    InputSignal,
    Type,
    InputSignalWithTransform,
    ModelSignal,
} from '@angular/core';
import { filter, map, pipe } from 'rxjs';

/** Type alias for all possible input signal types */
type InputSignalType =
    | InputSignalWithTransform<any, any>
    | InputSignal<any>
    | ModelSignal<any>;

/** Helper type to extract the value type from a signal */
type InputSignalValue<T> = T extends
    | InputSignalWithTransform<infer U, any>
    | InputSignal<infer U>
    | ModelSignal<infer U>
    ? U
    : never;

/** Helper type to extract input and model signals from a component */
export type ComponentSignalInputs<TComponent> = {
    [P in keyof TComponent as TComponent[P] extends InputSignalType
        ? P
        : never]: InputSignalValue<TComponent[P]>;
};

/**
 * Helper type to extract properties from a component
 * and infer types from input and model signals
 *
 * @note unfortunately cannot isolate decorator-based inputs
 * and does not work with aliased properties
 */
export type ComponentProperties<TComponent> = {
    [P in keyof TComponent]: TComponent[P] extends InputSignalType
        ? InputSignalValue<TComponent[P]>
        : // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
          TComponent[P] extends Function
          ? never
          : TComponent[P];
};

export interface IDialogPosition {
    top: number;
    right: number;
    bottom: number;
    left: number;
}

export interface IDialogData<TComponent> {
    dialogTitle: string;
    componentToLoad: Type<TComponent>;
    componentToLoadData?: Partial<ComponentProperties<TComponent>>;
    submitButtonLabel?: string;
    submitButtonIcon?: string;
    submitButtonTheme?: string;
    cancelButtonLabel?: string;
    confirmEnabled?: boolean;
    hideCancelButton?: boolean;
    position?: Partial<IDialogPosition>;
}

export type DialogResult =
    | boolean
    | { confirm: boolean; data: unknown }
    | undefined;

export function isBooleanDialogResult(result: DialogResult): result is boolean {
    return typeof result === 'boolean';
}

export function isObjectDialogResult(
    result: DialogResult
): result is { confirm: boolean; data: unknown } {
    return (
        typeof result === 'object' &&
        result != null &&
        'confirm' in result &&
        typeof result.confirm === 'boolean' &&
        'data' in result
    );
}

/** Type guard function type for dialog result data */
export type DialogResultTypeGuard<T> = (data: unknown) => data is T;

/**
 * Maps a dialog result to a standardized record with optional type narrowing for the data
 * @param typeGuardFn Optional type guard function to narrow the data type
 */
export function toDialogResultTypedRecord<T = unknown>(
    typeGuardFn?: DialogResultTypeGuard<T>
) {
    return map((result: DialogResult) => {
        if (isBooleanDialogResult(result)) {
            return { confirm: result, data: undefined as T | undefined };
        } else if (isObjectDialogResult(result)) {
            const typedResult =
                typeGuardFn &&
                result.data !== undefined &&
                typeGuardFn(result.data)
                    ? { ...result, data: result.data as T }
                    : {
                          ...result,
                          data: result.data as T | undefined,
                      };
            return typedResult;
        }
        return { confirm: false, data: undefined as T | undefined };
    });
}

/** Maps a dialog result to a simple boolean confirmation status */
export function toDialogConfirmation() {
    return map((result: DialogResult): boolean => {
        if (isBooleanDialogResult(result)) {
            return result;
        } else if (isObjectDialogResult(result)) {
            return result.confirm;
        }
        return false;
    });
}

/** Maps a dialog result to yes/no confirmation and filters for confirm only */
export function isDialogConfirmedOnly() {
    return pipe(
        toDialogConfirmation(),
        filter((confirmed) => !!confirmed)
    );
}

export enum ConfirmationType {
    Success = 'Success',
    Info = 'Info',
    Warning = 'Warning',
    Error = 'Error',
    Default = 'Default',
}

export interface IConfirmationDialogData {
    title: string;
    text1?: string;
    text2?: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
    confirmButtonType?: ConfirmationType;
    type?: ConfirmationType;
    showCloseIcon?: boolean;
    cancelButtonVisible?: boolean;
}
