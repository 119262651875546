<div class="flex justify-between qpx-card-title">
    <span>Building Manual</span>
    <a
        class="flex align-center qpx-nav-link"
        [routerLink]="['/buildings', buildingGuid(), 'site-details']"
        routerLinkActive="active"
    >
        @if (canUpdate()) {
            <span class="qpx-nav-link-item">Update</span>
            <mat-icon class="qpx-nav-link-item material-symbols-outlined">
                chevron_right
            </mat-icon>
        }
    </a>
</div>

<div class="flex wrap align-center justify-between statistics">
    <span class="qpx-percentage">
        {{ buildingManualPercentage() }}
        <span class="qpx-percentage-sign">%</span>
        <span class="qpx-percentage-complete">Complete</span>
    </span>

    <div class="progress-wrapper">
        <mat-label for="mat-progress-bar"
            >{{ missingKeyItemsNo() }} items missing</mat-label
        >
        <mat-progress-bar
            id="mat-progress-bar"
            mode="determinate"
            [value]="progressValue()"
        />
    </div>
</div>
