/**
 * @note use the address pipe for post-creation rendering
 * @deprecated use `toAddressFormat` instead
 */
export function transformAddress(address: string): string {
    if (!address) {
        return '';
    }

    // Split the address into main parts based on spaces
    const parts = address.split(' ');

    // Extract and format the postcode and state
    const postcode = parts.pop();
    const state = parts.pop();

    // Join the remaining parts to handle the street address and city
    const remainingParts = parts.join(' ').split(',');

    // Process the street address part
    const streetAddress = remainingParts[0].split(' ');
    const streetNumber = streetAddress[0];
    const streetName = streetAddress.slice(1).map(capitalize).join(' ');

    // Process the city part
    const city = remainingParts[1].trim().split(' ').map(capitalize).join(' ');

    // Combine all parts into the final formatted address
    const formattedAddress = `${streetNumber} ${streetName}, ${city}, ${state} ${postcode}`;

    return formattedAddress;
}

/** Helper function to capitalize the first letter of a word */
function capitalize(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export const ADDRESS_MODE = {
    Street: 'street',
    City: 'city',
    Full: 'full',
} as const;
export type AddressMode = (typeof ADDRESS_MODE)[keyof typeof ADDRESS_MODE];

export function toAddressFormat(
    value: unknown,
    mode: AddressMode = ADDRESS_MODE.Full
): string {
    if (typeof value === 'string') {
        switch (mode) {
            case ADDRESS_MODE.Street: {
                const streetAddressFromFullAddress = value.split(',')[0];
                return streetAddressFromFullAddress;
            }
            case ADDRESS_MODE.City: {
                const cityFromAddress = value.split(',').slice(1).join('');
                return cityFromAddress;
            }
            case ADDRESS_MODE.Full:
            default: {
                const valueAsArr = value.split(',');
                return (
                    (valueAsArr.length === 4 &&
                        `${valueAsArr[0]}, ${valueAsArr[1]}, ${valueAsArr[2]} ${valueAsArr[3]}`) ||
                    value
                );
            }
        }
    }

    if (!value || !isRecord(value)) return '';

    const {
        streetNumber,
        streetName,
        city,
        state,
        postcode,
        address,
        buildingName,
        buildingAddress,
        buildingCity,
    } = getValuesFromUnknownRecord(value);

    switch (mode) {
        case ADDRESS_MODE.Street: {
            const hasStreetFromKeys = hasAddressKeys({
                mode: ADDRESS_MODE.Street,
                streetNumber,
                streetName,
            });

            const sanitisedStreetAddress = buildingAddress.split(',')[0];
            const sanitisedBuildingName = buildingName.split(',')[0];
            const streetAddressFromFullAddress = address.split(',')[0];

            return (
                (hasStreetFromKeys && `${streetNumber} ${streetName}`) ||
                sanitisedStreetAddress ||
                sanitisedBuildingName ||
                streetAddressFromFullAddress ||
                ''
            );
        }
        case ADDRESS_MODE.City: {
            const hasCityFromKeys = hasAddressKeys({
                mode: ADDRESS_MODE.City,
                city,
                state,
                postcode,
            });

            const cityFromFullAddress = address.split(',').slice(1).join('');

            const sanitisedCityLocation = buildingCity.split(',').join('');

            return (
                (hasCityFromKeys && `${city} ${state} ${postcode}`) ||
                cityFromFullAddress ||
                sanitisedCityLocation ||
                ''
            );
        }

        case ADDRESS_MODE.Full:
        default: {
            const hasAddressFromKeys = hasAddressKeys({
                mode: ADDRESS_MODE.Full,
                streetNumber,
                streetName,
                city,
                state,
                postcode,
            });

            const buildingAddressAndCity = prepareFullAddressFromAddressCity({
                buildingAddress,
                buildingCity,
            });

            return (
                (hasAddressFromKeys &&
                    `${streetNumber} ${streetName}, ${city}, ${state} ${postcode}`) ||
                address ||
                buildingAddressAndCity ||
                buildingName ||
                buildingAddress ||
                ''
            );
        }
    }
}

function hasAddressKeys({
    mode,
    streetNumber,
    streetName,
    city,
    state,
    postcode,
}: {
    mode: AddressMode;
    streetNumber?: string;
    streetName?: string;
    city?: string;
    state?: string;
    postcode?: string;
}) {
    switch (mode) {
        case ADDRESS_MODE.Street: {
            return streetNumber && streetName;
        }

        case ADDRESS_MODE.City: {
            return city && state && postcode;
        }

        case ADDRESS_MODE.Full:
        default: {
            return streetNumber && streetName && city && state && postcode;
        }
    }
}

function prepareFullAddressFromAddressCity({
    buildingAddress,
    buildingCity,
}: {
    buildingAddress: string;
    buildingCity: string;
}) {
    if (!buildingAddress || !buildingCity) return '';

    const sanitisedStreetAddress = buildingAddress.split(',')[0];
    return `${sanitisedStreetAddress}, ${buildingCity}`;
}

function getValuesFromUnknownRecord(value: Record<string, unknown>): {
    streetNumber: string;
    streetName: string;
    city: string;
    state: string;
    postcode: string;
    /** full */
    address: string;
    /** streetNumber and streetName */
    buildingName: string;
    /** streetNumber and streetName OR full */
    buildingAddress: string;
    /** city, state and postcode */
    buildingCity: string;
} {
    /** full */
    const address =
        typeof value['address'] === 'string' ? value['address'] : '';

    const streetNumber =
        typeof value['streetNumber'] === 'string' ? value['streetNumber'] : '';
    const streetName =
        typeof value['streetName'] === 'string' ? value['streetName'] : '';
    const city = typeof value['city'] === 'string' ? value['city'] : '';
    const state = typeof value['state'] === 'string' ? value['state'] : '';
    const postcode =
        typeof value['postcode'] === 'number'
            ? value['postcode'].toString()
            : '';

    /** streetNumber and streetName */
    const buildingName =
        typeof value['buildingName'] === 'string' ? value['buildingName'] : '';

    /** streetNumber and streetName */
    const buildingAddress =
        typeof value['buildingAddress'] === 'string'
            ? value['buildingAddress']
            : '';

    /** city, state and postcode */
    const buildingCity =
        typeof value['buildingCity'] === 'string' ? value['buildingCity'] : '';

    return {
        streetNumber,
        streetName,
        city,
        state,
        postcode,
        address,
        buildingName,
        buildingAddress,
        buildingCity,
    };
}

function isRecord(value: unknown): value is Record<string, unknown> {
    return typeof value === 'object' && !Array.isArray(value) && value != null;
}
