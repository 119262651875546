import {
    ChangeDetectionStrategy,
    Component,
    computed,
    input,
} from '@angular/core';
import { ColorContext } from '@quipex/shared/helpers';

@Component({
    selector: 'qpx-pill',
    template: `<span>{{ label() }}</span>`,
    host: {
        '[class]': 'className()',
        '[role]': "ariaRole() === 'status' ? 'status' : null",
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class QpxPill {
    public readonly label = input('');

    public readonly size = input<'md' | 'sm'>('md');

    public readonly context = input<ColorContext>('primary');

    public readonly ariaRole = input<'decorative' | 'status'>('decorative');

    protected readonly className = computed(() => {
        const _className = [
            'qpx-pill',
            this.size(),
            `bg-${this.context()}`,
            `text-on-${this.context()}`,
        ];

        return _className.join(' ');
    });
}
