import {
    computed,
    DestroyRef,
    Directive,
    inject,
    input,
    OnInit,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { MatCheckbox } from '@angular/material/checkbox';
import { distinctUntilChanged } from 'rxjs';

@Directive({
    selector: 'mat-checkbox[qpx]',
    host: {
        '[attr.id]': 'id()',
        '[class]': 'className()',
    },
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class QpxCheckbox implements OnInit {
    private readonly checkbox = inject(MatCheckbox, { host: true });
    private readonly destroyRef = inject(DestroyRef);

    public readonly id = input.required<string>();
    public readonly variant = input<'square' | 'round'>('square');
    public readonly ripple = input<boolean>(false);
    private readonly ripple$ = toObservable(this.ripple);

    public readonly context = input<'primary' | 'warn' | 'accent'>('primary');
    private readonly context$ = toObservable(this.context);

    protected readonly className = computed(() => {
        const _classNames = ['qpx-checkbox', `mat-${this.context()}`];
        if (this.variant() === 'square') {
            _classNames.push('square');
        }
        return _classNames.join(' ');
    });

    ngOnInit(): void {
        this.ripple$
            .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (value) => (this.checkbox.disableRipple = !value),
            });

        this.context$
            .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (value) => (this.checkbox.color = value),
            });
    }
}
