// file.service.ts
import { Injectable, inject } from '@angular/core';
import { FileHelper } from '@quipex/shared/helpers';
import { ConfirmationType } from '../models/typings/dialog-data.interface';
import { environment } from 'src/environments/environment';
import { DialogService } from './dialog.service';

@Injectable({
    providedIn: 'root',
})
export class FileService {
    private readonly dialogService = inject(DialogService);

    validateFiles(_files: File[]): File[] {
        const files = this.validateFileType(_files);

        return this.validateFileSize(files) ? files : [];
    }

    private validateFileSize(files: File[]): boolean {
        let totalSize = 0;

        for (const file of files) {
            totalSize += file.size;
        }

        const sizeInMb = this.bytesToMegaBytes(totalSize);
        if (sizeInMb > environment.maxFileSizeInMb) {
            this.dialogService.openConfirmationDialog({
                title: 'File size is too big',
                text1: `File must not be greater than ${environment.maxFileSizeInMb}MB`,
                text2: '',
                type: ConfirmationType.Error,
                confirmButtonType: ConfirmationType.Default,
                confirmButtonText: 'OK',
                cancelButtonText: '',
                showCloseIcon: false,
                cancelButtonVisible: false,
            });
            return false;
        }
        return true;
    }

    private validateFileType(files: File[]): File[] {
        const validFiles = [];
        const invalidFiles = [];

        for (const file of files) {
            if (FileHelper.checkFileUploadType(file.name)) {
                validFiles.push(file);
            } else {
                invalidFiles.push(file);
            }
        }

        const supportedFileTypesArray = environment.documentUploadTypes
            .slice()
            .sort((a, b) => a.localeCompare(b));
        const supportedFileTypes =
            supportedFileTypesArray.length > 1
                ? `${supportedFileTypesArray.slice(0, -1).join(', ')} and ${supportedFileTypesArray[supportedFileTypesArray.length - 1]}`
                : supportedFileTypesArray[0];

        const textInvalidFileNames = invalidFiles
            .map((file) => `• ${file.name}`)
            .join('\n');

        if (invalidFiles.length) {
            this.dialogService.openConfirmationDialog({
                title: 'File types not allowed',
                text1: `We support types ${supportedFileTypes}.`,
                text2: `\nThe following files will not be uploaded:\n${textInvalidFileNames}`,
                type: ConfirmationType.Error,
                confirmButtonType: ConfirmationType.Default,
                confirmButtonText: 'OK',
                showCloseIcon: false,
                cancelButtonVisible: false,
            });
        }

        return validFiles;
    }

    private bytesToMegaBytes(bytes: number) {
        return bytes / (1024 * 1024);
    }
}
