import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
    ConfirmationDialogComponent,
    DialogComponent,
} from '@quipex/shared/components';
import { Subject } from 'rxjs';
import {
    IDialogData,
    ConfirmationType,
    IConfirmationDialogData,
    DialogResult,
} from '../models/typings/dialog-data.interface';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    private readonly matDialog = inject(MatDialog);

    isProcessing$ = new Subject<boolean>();
    canConfirm$ = new Subject<boolean>();
    close$ = new Subject<DialogResult>();
    requestCancel$ = new Subject<void>();
    requestConfirm$ = new Subject<void>();
    confirmLabelChange$ = new Subject<string>();
    confirmButtonTheme$ = new Subject<string>();
    confirmIconChange$ = new Subject<string>();
    cancelButtonVisible$ = new Subject<boolean>();
    dialogTitle$ = new Subject<string>();

    openDialog<T>(
        dialogData: IDialogData<T>,
        panelClass: string[] = [],
        disableClose = true
    ): MatDialogRef<DialogComponent, DialogResult> {
        return this.matDialog.open<DialogComponent, any, DialogResult>(
            DialogComponent,
            {
                autoFocus: 'first-heading',
                restoreFocus: false,
                panelClass: panelClass,
                disableClose: disableClose,
                data: {
                    dialogTitle: dialogData.dialogTitle,
                    componentToLoad: dialogData.componentToLoad,
                    componentToLoadData: dialogData.componentToLoadData,
                    submitButtonLabel: dialogData.submitButtonLabel,
                    submitButtonIcon: dialogData.submitButtonIcon,
                    submitButtonTheme: dialogData.submitButtonTheme,
                    cancelButtonLabel: dialogData.cancelButtonLabel,
                    hideCancelButton: dialogData.hideCancelButton,
                    confirmEnabled: dialogData.confirmEnabled,
                },
                position: {
                    top:
                        typeof dialogData.position?.top === 'number' &&
                        dialogData.position?.top >= 0
                            ? `${dialogData.position.top}px`
                            : '',
                    right:
                        typeof dialogData.position?.right === 'number' &&
                        dialogData.position?.right >= 0
                            ? `${dialogData.position.right}px`
                            : '',
                    bottom:
                        typeof dialogData.position?.bottom === 'number' &&
                        dialogData.position?.bottom >= 0
                            ? `${dialogData.position.bottom}px`
                            : '',
                    left:
                        typeof dialogData.position?.left === 'number' &&
                        dialogData.position?.left >= 0
                            ? `${dialogData.position.left}px`
                            : '',
                },
            }
        );
    }

    closeDialog(isConfirm: DialogResult = false): void {
        this.close$.next(isConfirm);
    }

    setIsProcessing(isProcessing: boolean): void {
        this.isProcessing$.next(isProcessing);
    }

    setCanConfirm(canConform: boolean): void {
        this.canConfirm$.next(canConform);
    }

    requestCancel(): void {
        this.requestCancel$.next();
    }

    requestConfirm(): void {
        this.requestConfirm$.next();
    }

    updateConfirmLabel(label: string): void {
        this.confirmLabelChange$.next(label);
    }

    updateConfirmIcon(icon: string): void {
        this.confirmIconChange$.next(icon);
    }

    setDialogTitle(title: string): void {
        this.dialogTitle$.next(title);
    }

    cancelButtonVisible(visible: boolean): void {
        this.cancelButtonVisible$.next(visible);
    }

    setConfirmButtonTheme(theme: 'primary' | 'accent'): void {
        this.confirmButtonTheme$.next(theme);
    }

    openConfirmationDialog(
        options: IConfirmationDialogData
    ): MatDialogRef<ConfirmationDialogComponent> {
        return this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                title: options?.title ?? '',
                text1: options?.text1 ?? '',
                text2: options?.text2 ?? '',
                confirmButtonText: options?.confirmButtonText ?? 'OK',
                cancelButtonText: options?.cancelButtonText ?? 'Cancel',
                type: options?.type ?? ConfirmationType.Default,
                confirmButtonType:
                    options?.confirmButtonType ?? ConfirmationType.Default,
                showCloseIcon: options?.showCloseIcon ?? false,
                cancelButtonVisible: options?.cancelButtonVisible ?? false,
            },
            panelClass: ['confirm-dialog'],
            disableClose: true,
        });
    }
}
