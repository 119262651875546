import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
    name: 'qpxKebabCase',
})
export class KebabCasePipe implements PipeTransform {
    /**
     * Transforms a given string to kebab case, converting spaces to hyphens and camelCase to kebab-case.
     *
     * Example usage in a template:
     * ```
     * {{ 'Hello World' | qpxKebabCase }}
     * {{ 'myCamelCase' | qpxKebabCase }}
     * ```
     * This will render as:
     * ```
     * hello-world
     * my-camel-case
     * ```
     *
     * @param value - The input string to transform.
     * @returns The transformed string in kebab-case.
     */
    transform(value: string): string {
        return value
            .replace(/\s+/g, '-') // replace spaces with hyphens
            .replace(/([a-z])([A-Z])/g, '$1-$2') // replace camelCase with kebab-case
            .toLowerCase();
    }
}
