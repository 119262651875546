import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FeatureFlag, FEATURE_FLAG } from '../models/feature-flags';

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagService {
    private readonly featureFlags: Map<FeatureFlag, boolean> = new Map();

    constructor() {
        this.initializeFeatureFlags();
    }

    private initializeFeatureFlags(): void {
        const flags = environment.featureFlags;

        // Type assertion to ensure keys are of type FeatureFlag
        Object.entries(flags).forEach(([key, _value]) => {
            if (!this.isValidFeatureFlag(key)) {
                return;
            }

            const value = typeof _value === 'boolean' ? _value : false;

            this.featureFlags.set(key, value);
        });
    }

    private isValidFeatureFlag(key: string): key is FeatureFlag {
        return Object.values<string>(FEATURE_FLAG).includes(key);
    }

    /**
     * Checks if a specific feature flag is enabled
     * @param flagName - The name of the feature flag to check
     * @param defaultValue - Optional default value if flag is not found (defaults to false)
     * @returns boolean indicating if the feature is enabled
     */
    isFeatureEnabled(flagName: FeatureFlag, defaultValue = false): boolean {
        return this.featureFlags.has(flagName)
            ? this.featureFlags.get(flagName)!
            : defaultValue;
    }
}
