export interface IOrganisationSearch {
    id: string;
    companyName: string;
}

export function isOrganisationSearch(
    value: unknown
): value is IOrganisationSearch {
    return (
        typeof value === 'object' &&
        value != null &&
        'id' in value &&
        typeof value.id === 'string' &&
        'companyName' in value &&
        typeof value.companyName === 'string'
    );
}
