export type TypeMapValue<K extends PropertyKey, M> = M extends {
    [P in K]: unknown;
}
    ? M[K]
    : never;

export function getStrictMap<K extends PropertyKey, M, V = TypeMapValue<K, M>>(
    map: Map<K, unknown>,
    key: K
): V {
    const value = map.get(key);
    if (value === undefined) {
        throw new Error(`Value not found for key: ${String(key)}`);
    }
    return value as V;
}
