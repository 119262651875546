<header mat-dialog-title class="header">
    @switch (data.type) {
        @case (confirmationType.Warning) {
            <mat-icon
                class="material-symbols-outlined status-icon warning"
                aria-label="Warning"
                >warning</mat-icon
            >
        }
        @case (confirmationType.Error) {
            <mat-icon
                class="material-symbols-outlined status-icon cancel"
                aria-label="Error"
                >error</mat-icon
            >
        }
        @case (confirmationType.Info) {
            <mat-icon
                class="material-symbols-outlined status-icon info"
                aria-label="Info"
                >info</mat-icon
            >
        }
        @case (confirmationType.Success) {
            <mat-icon
                class="material-symbols-outlined status-icon success"
                aria-label="Success"
                >check_circle</mat-icon
            >
        }
    }

    @if (data.title) {
        <h3 qpxHeading class="title">
            {{ data.title }}
        </h3>
    }

    @if (data.showCloseIcon) {
        <div class="actions">
            <button
                qpxButton="unstyled"
                (click)="onCancel()"
                aria-label="Close"
            >
                <mat-icon>close</mat-icon>
            </button>
        </div>
    }
</header>

<mat-dialog-content>
    @if (data.text1) {
        <div class="whitespace-pre-line">{{ data.text1 }}</div>
    }

    @if (data.text2) {
        <div class="whitespace-pre-line">{{ data.text2 }}</div>
    }
</mat-dialog-content>

<mat-dialog-actions>
    @if (data.cancelButtonVisible) {
        <button
            class="cancel-button"
            qpxButton
            mat-stroked-button
            (click)="onCancel()"
        >
            <mat-icon class="qpx-icon-sm"> close </mat-icon>
            {{ data.cancelButtonText || 'Cancel' }}
        </button>
    }
    <button
        qpxButton
        mat-flat-button
        data-testId="confirmButton"
        [context]="confirmButtonColor()"
        mat-dialog-close="confirm"
        cdkFocusInitial
    >
        {{ data.confirmButtonText || 'Confirm' }}
    </button>
</mat-dialog-actions>
