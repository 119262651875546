import { inject, Injectable } from '@angular/core';
import {
    ClassifyDocumentResponse,
    DocDto,
    DocumentApiService,
} from '@quipex/shared/data';
import { FileHelper } from '@quipex/shared/helpers';
import { firstValueFrom } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class BulkUploadFilesService {
    private readonly documentService = inject(DocumentApiService);

    async processFiles(files: File[], canClassify: boolean): Promise<DocDto[]> {
        const newDocs = this.createInitialDocDtos(files);

        if (canClassify) {
            await this.classifyDocuments(newDocs, files);
        }

        return newDocs;
    }

    private createInitialDocDtos(files: File[]): DocDto[] {
        return Array.from(files).map((file) => ({
            id: uuidv4(),
            autoTag: [],
            isKeyDocument: false,
            file,
            documentType: null,
        }));
    }

    private async classifyDocuments(
        docs: DocDto[],
        files: File[]
    ): Promise<void> {
        const classifiableFiles = files.filter((file) =>
            FileHelper.isFileAutoTagType(file.name)
        );

        if (!classifiableFiles.length) return;

        const classified = await firstValueFrom(
            this.documentService.classifyDocuments(classifiableFiles)
        );

        this.updateDocumentsWithClassification(docs, classified);
    }

    private updateDocumentsWithClassification(
        docs: DocDto[],
        classified: ClassifyDocumentResponse[]
    ): void {
        classified.forEach((result) => {
            const doc = docs.find((d) => d.file.name === result.fileName);
            if (doc) {
                doc.isKeyDocument = true;
                doc.documentType = result.documentType;
            }
        });
    }
}
