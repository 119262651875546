import { computed, Directive, input } from '@angular/core';
import { COLOR_CONTEXT, ColorContext } from '@quipex/shared/helpers';

@Directive({
    selector: '[qpxHeading]',
    host: {
        '[class]': 'className()',
    },
})
export class QpxHeadingDirective {
    public readonly context = input<ColorContext, ColorContext | ''>(
        COLOR_CONTEXT.Primary,
        {
            alias: 'qpxHeading',
            transform: (value: ColorContext | '') =>
                value || COLOR_CONTEXT.Primary,
        }
    );

    protected readonly className = computed(() => {
        return ['qpx-font-heading-main', 'm-0', `text-${this.context()}`].join(
            ' '
        );
    });
}
