import { IOCDetailsExtraction } from '../add-new-building.model';
import { DocumentTypeEnum } from '../enums.model';

export enum BuildingTaskStatusEnum {
    NotResolved = 1,
    Overdue = 2,
    Upcoming = 3,
    InProgress = 4,
    Resolved = 5,
    Completed = 6,
    Triage = 7,
    OverdueCompleted = 8,
    Review = 9,
}

export enum BuildingTaskStatusNamesEnum {
    NotResolved = 'Not resolved',
    Overdue = 'Overdue',
    InProgress = 'In progress',
    Upcoming = 'Upcoming',
    Completed = 'Completed',
    Resolved = 'Resolved',
    Triage = 'Triage',
    Review = 'Review Req.',
    OverdueCompleted = 'Overdue (comp)',
}

export enum BuildingTaskTypeEnum {
    ScheduleMaintenance = 1,
    GeneralMaintenance,
    Defect,
    AnnualStatement,
    AssetRegister,
    Review,
    Inspection,
    SafetyRelated,
    ContractorCompliance,
}

export enum BuildingTaskTypeNamesEnum {
    ScheduleMaintenance = 'Scheduled maintenance',
    GeneralMaintenance = 'General maintenance',
    Defect = 'Defect',
    AnnualStatement = 'Annual statement',
    AssetRegister = 'Asset register',
    Review = 'Verify updates',
    Inspection = 'Inspection',
    SafetyRelated = 'Safety related',
    ContractorCompliance = 'Contractor Compliance',
}

export enum BuildingTaskCriticalEnum {
    Emergency = 1,
    Critical,
    Urgent,
    NotCritical,
    NA,
}

export enum BuildingTaskCriticalNamesEnum {
    Emergency = 'Emergency',
    Critical = 'Critical',
    Urgent = 'Urgent',
    NotCritical = 'Not critical',
    NA = 'NA',
}

export interface ITaskAssignedBuilding {
    id: number;
    identifier: string;
    buildingName: string;
}

export interface ITaskAssignedOrganisation {
    id: number;
    organizationName: string;
}

export interface ITaskAssignedUser {
    id: number;
    userName: string;
}

export interface GetTaskListRequestDTO {
    pageNo: number;
    pageSize: number;
    buildingIds: number[];
    taskTypes: BuildingTaskTypeEnum[];
    isESMs: boolean[];
    statuses: BuildingTaskStatusEnum[];
    criticals: BuildingTaskCriticalEnum[];
    assignOrgIds: number[];
    assignUserIds: number[];
}

export interface IGetTaskListResponse {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    pageIndex: number;
    totalCount: number;
    totalPages: number;
    items: ITaskItem[];
}

export interface IGetTaskResponse {
    id: number;
    identifier: string;
    description: string;
    buildingName: string;
    buildingId: string;
    assignedOrgId: number;
    assignedUserId: number;
    assignedUserName: string;
    status: BuildingTaskStatusEnum;
    type: BuildingTaskTypeEnum;
    isCritical: BuildingTaskCriticalEnum;
    raisedDate: Date;
    age: number;
    lastUpdatedAt?: Date;
    lastUpdatedBy?: string;
    completionDate: Date;
    accessDaysAndHours?: string;
    contactNameForAccess?: string;
    email?: string;
    phoneNumber?: string;
    documents: IGetTaskDocument[];
    notes: IGetTaskNote[];
    defectDTOs: IGetTaskDefectDto[];
    inspectionBasicDetails: IGetTaskInspectionBasicDetails;
    assetId: number | null;
}

export interface IDocumentProcessingDetails {
    id: string;
    name?: string;
    type: DocumentTypeEnum;
    ocExtractionDetails?: IOCDetailsExtraction;
}

export interface IDocumentProcessingDetailsResponse {
    taskId: string;
    documents?: IDocumentProcessingDetails[];
}

export interface IGetTaskInspectionBasicDetails {
    inspectionTaskId: string;
    inspectionTaskDescription: string;
}

export interface IGetTaskDefectDto {
    id: string;
    description: string;
    isCritical: BuildingTaskCriticalEnum;
}

export interface IGetTaskNote {
    note: string;
    userName: string;
    date: Date;
}

export interface IGetTaskDocument {
    documentId: string;
    documentName: string;
}

export interface ITaskItem {
    assignedOrgId: number;
    assignedUserId: number;
    assignedUserName: string;
    building: string;
    buildingId: string;
    description: string;
    id: string;
    isCritical: BuildingTaskCriticalEnum;
    status: BuildingTaskStatusEnum;
    type: BuildingTaskTypeEnum;
    isESM: boolean;
    contractorId?: string;
}

export interface IUpdateAssignedOrg {
    taskId: string;
    assignedOrganizationId: number;
}

export interface IUpdateAssignedUser {
    taskId: string;
    assignedUserId: number | null;
}

export interface IAddTaskPostModel {
    buildingId: number;
    type: BuildingTaskTypeEnum;
    status: BuildingTaskStatusEnum;
    isCritical: BuildingTaskCriticalEnum;
    description?: string | null;
    assetId?: number | null;
    assignedOrgId?: number | null;
    assignedUserId?: number | null;
    notes?: string[] | null;
    accessDaysAndHours?: string | null;
    contactNameForAccess?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    locationInBuilding: string;
}

export interface IUpdateDefectPostModel {
    taskId: string;
    status: BuildingTaskStatusEnum;
    completionDate?: Date | null;
    assignedOrgId?: number | null;
    assignedUserId?: number | null;
    addedNotes: string[];
    documents: File[];
    accessDaysAndHours?: string | null;
    contactNameForAccess?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    assetId?: number | null;
    defects: {
        id?: string;
        description: string;
        isCritical: BuildingTaskCriticalEnum;
    }[];
}

export interface IUpdateTaskPostModel {
    taskId: string;
    status: BuildingTaskStatusEnum;
    assignedOrgId?: number | null;
    assignedUserId?: number | null;
    addedNotes: string[];
    accessDaysAndHours?: string | null;
    contactNameForAccess?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    assetId?: number | null;
}

export interface ICompleteTaskPostModel {
    taskId: string;
    completionDate?: Date | null;
    assignedOrgId?: number | null;
    assignedUserId?: number | null;
    addedNotes: string[];
    documents: File[];
    addedDefectDTOs: any;
}
