import {
    ChangeDetectionStrategy,
    Component,
    inject,
    signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmationType, IConfirmationDialogData } from '@quipex/shared/data';
import { QpxButtonDirective, QpxTypography } from '@quipex/shared/directives';

type ButtonColor = 'primary' | 'warn' | 'accent';

@Component({
    selector: 'qpx-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    imports: [
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        QpxButtonDirective,
        QpxTypography,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent {
    private readonly dialogRef =
        inject<MatDialogRef<ConfirmationDialogComponent>>(MatDialogRef);
    protected readonly data = inject<IConfirmationDialogData>(MAT_DIALOG_DATA);

    protected readonly confirmationType = ConfirmationType;

    protected readonly confirmButtonColor = signal<ButtonColor>('accent');

    constructor() {
        this.getConfirmButtonColor();
    }

    protected onCancel(): void {
        this.dialogRef.close();
    }

    private getConfirmButtonColor(): void {
        switch (this.data.confirmButtonType) {
            case ConfirmationType.Info: {
                this.confirmButtonColor.set('primary');
                break;
            }
            case ConfirmationType.Warning:
            case ConfirmationType.Error: {
                this.confirmButtonColor.set('warn');
                break;
            }
        }
    }
}
