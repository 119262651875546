export function isObjectEqual<T extends Record<string, unknown>>(
    object1: T,
    object2: T
): boolean {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            (areObjects && !isObjectEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }

    return true;
}

function isObject(object: unknown): object is Record<string, unknown> {
    return object != null && typeof object === 'object';
}

export function isSetEqual<T extends string | number>(
    set1: Set<T>,
    set2: Set<T>
): boolean {
    if (set1.size !== set2.size) return false;

    for (const item of set1) {
        if (!set2.has(item)) return false;
    }

    for (const item of set2) {
        if (!set1.has(item)) return false;
    }
    return true;
}
