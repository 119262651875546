@if (withDragAndDrop) {
    <div class="drag-drop-area" qpxDnd (fileDropped)="onFileDropped($event)">
        <!-- Text above the button -->
        <div>Drag and drop your documents / files here, or</div>
        <!-- Upload buttons in the center -->
        <div class="drag-drop-area__button">
            @if (allowBothFolderAndFileSelection) {
                <div class="button-group">
                    <input
                        #fileInput
                        type="file"
                        style="display: none"
                        [disabled]="!uploadEnabled"
                        [multiple]="multiple"
                        (change)="fileBrowseHandler($event)"
                        (click)="onFileInputClick($event)"
                    />
                    <input
                        #folderInput
                        type="file"
                        style="display: none"
                        [disabled]="!uploadEnabled"
                        [multiple]="multiple"
                        webkitdirectory
                        directory
                        (change)="fileBrowseHandler($event)"
                        (click)="onFileInputClick($event)"
                    />
                    <button
                        type="button"
                        mat-stroked-button
                        [disabled]="!uploadEnabled"
                        (click)="fileInput.click()"
                    >
                        <mat-icon class="qpx-icon" color="accent"
                            >upload</mat-icon
                        >
                        Browse Files
                    </button>
                    <button
                        type="button"
                        mat-stroked-button
                        [disabled]="!uploadEnabled"
                        (click)="folderInput.click()"
                    >
                        <mat-icon class="qpx-icon" color="accent"
                            >folder</mat-icon
                        >
                        Browse Folders
                    </button>
                </div>
            } @else {
                <input
                    #fileInput
                    type="file"
                    style="display: none"
                    [disabled]="!uploadEnabled"
                    [multiple]="multiple"
                    [attr.webkitdirectory]="allowFolderSelection ? '' : null"
                    [attr.directory]="allowFolderSelection ? '' : null"
                    (change)="fileBrowseHandler($event)"
                    (click)="onFileInputClick($event)"
                />
                <button
                    type="button"
                    mat-stroked-button
                    [disabled]="!uploadEnabled"
                    (click)="openFileBrowser()"
                >
                    <mat-icon class="qpx-icon" color="accent">{{
                        allowFolderSelection ? 'folder' : 'upload'
                    }}</mat-icon>
                    Browse {{ allowFolderSelection ? 'Folders' : 'Files' }}
                </button>
            }
        </div>
    </div>
} @else {
    @if (buttonType) {
        <div>
            @if (allowBothFolderAndFileSelection) {
                <div class="button-group">
                    <input
                        #fileInput
                        type="file"
                        style="display: none"
                        [disabled]="!uploadEnabled"
                        [multiple]="multiple"
                        (change)="fileBrowseHandler($event)"
                        (click)="onFileInputClick($event)"
                    />
                    <input
                        #folderInput
                        type="file"
                        style="display: none"
                        [disabled]="!uploadEnabled"
                        [multiple]="multiple"
                        webkitdirectory
                        directory
                        (change)="fileBrowseHandler($event)"
                        (click)="onFileInputClick($event)"
                    />
                    <button
                        type="button"
                        mat-stroked-button
                        [disabled]="!uploadEnabled"
                        (click)="fileInput.click()"
                    >
                        <mat-icon class="qpx-icon-sm">upload</mat-icon>
                        Upload Files
                    </button>
                    <button
                        type="button"
                        mat-stroked-button
                        [disabled]="!uploadEnabled"
                        (click)="folderInput.click()"
                    >
                        <mat-icon class="qpx-icon-sm">folder</mat-icon>
                        Upload Folders
                    </button>
                </div>
            } @else {
                <input
                    #fileInput
                    type="file"
                    [multiple]="multiple"
                    class="input-file"
                    style="display: none"
                    [attr.webkitdirectory]="allowFolderSelection ? '' : null"
                    [attr.directory]="allowFolderSelection ? '' : null"
                    (change)="fileBrowseHandler($event)"
                    (click)="onFileInputClick($event)"
                />
                <button
                    mat-stroked-button
                    id="uploadFile"
                    type="button"
                    (click)="fileInput.click()"
                >
                    <mat-icon class="qpx-icon-sm">{{
                        allowFolderSelection ? 'folder' : 'upload'
                    }}</mat-icon>
                    Upload {{ allowFolderSelection ? 'Folder' : 'File' }}
                </button>
            }
        </div>
    } @else {
        <div class="flex align-center">
            @if (allowBothFolderAndFileSelection) {
                <div class="button-group">
                    <input
                        #fileInput
                        type="file"
                        style="display: none"
                        [multiple]="multiple"
                        (change)="fileBrowseHandler($event)"
                        (click)="onFileInputClick($event)"
                    />
                    <input
                        #folderInput
                        type="file"
                        style="display: none"
                        [multiple]="multiple"
                        webkitdirectory
                        directory
                        (change)="fileBrowseHandler($event)"
                        (click)="onFileInputClick($event)"
                    />
                    <div (click)="fileInput.click()">
                        Upload Files
                        <mat-icon class="qpx-icon" color="accent"
                            >upload</mat-icon
                        >
                    </div>
                    <div (click)="folderInput.click()">
                        Upload Folders
                        <mat-icon class="qpx-icon" color="accent"
                            >folder</mat-icon
                        >
                    </div>
                </div>
            } @else {
                <input
                    #fileInput
                    type="file"
                    style="display: none"
                    [multiple]="multiple"
                    [attr.webkitdirectory]="allowFolderSelection ? '' : null"
                    [attr.directory]="allowFolderSelection ? '' : null"
                    (change)="fileBrowseHandler($event)"
                    (click)="onFileInputClick($event)"
                />
                <div (click)="fileInput.click()">
                    Upload {{ allowFolderSelection ? 'Folder' : 'File' }}
                    <mat-icon class="qpx-icon" color="accent">{{
                        allowFolderSelection ? 'folder' : 'upload'
                    }}</mat-icon>
                </div>
            }
        </div>
    }
}

