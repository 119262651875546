// eslint-disable-next-line @nx/enforce-module-boundaries
import { Environment } from '../../modules/shared/data/src/lib/models/environment';

export const environment: Environment = {
    appVersion: 'v1.0.0',
    isProd: false,
    apiBase: 'https://app-quipex-api-uat.azurewebsites.net',
    apiFunctionBase: 'https://app-quipex-fun-uat.azurewebsites.net/api',
    apiAIBase: 'https://app-quipex-ai-qa.azurewebsites.net',
    addressApiBase: 'https://app-quipex-address-api-staging.azurewebsites.net',
    scopes: {
        quipexApi: [
            'api://b064bdaf-8e23-441c-8e65-6aaae8660a72/access_as_user',
        ],
        addressApi: ['api://719d018d-af6c-4acb-8aa0-21581528e992/read_address'],
        aiApi: ['api://95a33e0f-a1cf-43a2-9e0e-70aa03d81037/ai_read'],
        functionApp: ['api://0e9cfa9e-9c4a-4868-bb2f-fd75b9d1d057/fun.read'],
        graphApi: [
            'https://graph.microsoft.com/Files.Read.All',
            'https://graph.microsoft.com/Sites.Read.All',
        ],
    },
    graphApiClientId: '9935c007-c1d3-4bab-bce9-bf55d1b0c9b5', // quipex-ui-staging clientId
    tenantId: '425035fc-2bef-4997-a2a0-8efeac33816f', // Quipex's tenant ID
    clientId: '9935c007-c1d3-4bab-bce9-bf55d1b0c9b5',
    sessionConfig: {
        inactiveTimeoutSeconds: 1800,
        idleTimeoutSeconds: 1,
    },
    imagePreviewFileTypes: [
        'jpeg',
        'jpeg',
        'gif',
        'png',
        'apng',
        'svg',
        'bmp',
        'ico',
        'avif',
        'jpg',
    ],
    documentUploadTypes: [
        'bmp',
        'jpeg',
        'jpg',
        'pdf',
        'png',
        'tiff',
        'tif',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'csv',
        'html',
        'htm',
        'mpp',
        'vsdx',
        'dwg',
        'bimx',
    ],
    documentAutoTagTypes: ['jpeg', 'jpg', 'pdf', 'png', 'doc', 'docx'],
    maxFileSizeInMb: 250,
    concurrentFilesUpload: 50, // number of files that can be uploaded at one time
    mapsApiKey: '#{API_KEY}#',
    instrumentationKey: 'd5cd4c34-2f4c-4842-8dee-338c2f172f39',
    autoTagMaxSizeMB: 25,
    clarityProjectId: 'oy58etsqay',
    featureFlags: {
        contractorManagement: true,
    },
} as const;
