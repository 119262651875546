<div class="notification">
    <button
        type="button"
        class="unstyled notification-icon"
        (click)="toggleNotifications()"
    >
        <mat-icon class="material-symbols-outlined"> notifications </mat-icon>
        <span class="sr-only">notifications</span>
        @if (unreadCount()) {
            <span class="notification-badge">{{ unreadCount() }}</span>
        }
    </button>

    @if (isDropdownOpen()) {
        <div class="notification-dropdown">
            <div class="header">
                <span>Notifications</span>
                <button
                    type="button"
                    class="unstyled close-icon"
                    (click)="toggleNotifications()"
                >
                    <mat-icon>close</mat-icon>
                </button>
            </div>

            <div class="notification-list">
                @if (notifications().length && isLoading()) {
                    <qpx-loader />
                }

                @for (notification of notifications(); track notification.id) {
                    <div class="notification-item">
                        <div
                            class="bullet"
                            [class.bullet-overdue]="
                                isOverdue(notification.description)
                            "
                            [class.bullet-upcoming]="
                                !isOverdue(notification.description)
                            "
                        ></div>
                        <button
                            type="button"
                            class="unstyled notification-content"
                            (click)="navigateToDetails(notification)"
                        >
                            <div class="title">
                                {{ notification.title }}
                            </div>
                            <div class="subtitle">
                                {{ notification.description }}
                            </div>
                            <div class="date">
                                {{ notification.createdAt | date: 'd MMM' }}
                            </div>
                        </button>

                        <button
                            type="button"
                            class="unstyled mark-as-read"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Mark as read"
                            (click)="markAsRead(notification.id)"
                        >
                            <mat-icon>visibility</mat-icon>
                        </button>
                    </div>
                } @empty {
                    @if (isLoading()) {
                        <div class="notification-item skeleton">
                            <qpx-loader
                                containerClass="flex justify-center align-center"
                            />
                        </div>
                    } @else {
                        <div class="notification-item title">
                            No new notifications to display
                        </div>
                    }
                }
            </div>
        </div>
    }
</div>
