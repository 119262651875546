export type FilterOptionId = number | boolean | string | null;

export type AppliedFilterOptionId<T extends FilterOptionId = FilterOptionId> =
    T;

export type FilterOption<T extends FilterOptionId = FilterOptionId> =
    | {
          id: T;
          name: string;
      }
    | {
          id: T;
          name: string;
          [key: string]: unknown;
      };

export interface Filter<
    T extends string = string,
    U extends FilterOptionId = FilterOptionId,
> {
    name: T;
    options: FilterOption<U>[];
}

const _ALL_OPTION_NAME = 'All';
const _UNASSIGNED_OPTION_NAME = 'Unassigned';
const _NONE_OPTION_NAME = 'None';

/**
 * Represents a standard filter option with type-safe id and name.
 * Provides utility methods for type conversion.
 *
 * @template T - Type of the filter option id, must extend `FilterOptionId`
 */
export class StandardFilterOption<T extends FilterOptionId = FilterOptionId> {
    /**
     * Private constructor to enforce creation through static factory method
     * @param id - Unique identifier for the filter option
     * @param name - Display name of the filter option
     */
    private constructor(
        public readonly id: T,
        public readonly name: string
    ) {}

    /**
     * Creates a new StandardFilterOption instance
     * @template T - Type of the filter option id
     * @param id - Unique identifier for the filter option
     * @param name - Display name of the filter option
     * @returns New StandardFilterOption instance
     */
    static create<T extends FilterOptionId>(id: T, name: string) {
        return new StandardFilterOption(id, name);
    }

    /**
     * Converts the filter option id to string type
     * @returns New FilterOption with string id
     */
    toString(): FilterOption<string> {
        return StandardFilterOption.create(String(this.id), this.name);
    }

    /**
     * Converts the filter option id to number type
     * @returns New FilterOption with number id
     */
    toNumber(): FilterOption<number> {
        return StandardFilterOption.create(Number(this.id), this.name);
    }

    /**
     * Converts the filter option id to null
     * @returns New FilterOption with null id
     */
    toNull(): FilterOption<null> {
        return StandardFilterOption.create(null, this.name);
    }
}

export const ALL_OPTION = StandardFilterOption.create(0, _ALL_OPTION_NAME);
export const UNASSIGNED_OPTION = StandardFilterOption.create(
    -1,
    _UNASSIGNED_OPTION_NAME
);
export const NONE_OPTION = StandardFilterOption.create(-1, _NONE_OPTION_NAME);

export const STANDARD_FILTER_OPTION = {
    All: ALL_OPTION,
    Unassigned: UNASSIGNED_OPTION,
    None: NONE_OPTION,
    Custom: <T extends FilterOptionId = FilterOptionId>(id: T, name: string) =>
        StandardFilterOption.create(id, name),
} as const;
