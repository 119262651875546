export const COLOR_CONTEXT = {
    Primary: 'primary',
    Secondary: 'secondary',
    PrimaryMuted: 'primary-muted',
    Accent: 'accent',
    Input: 'input',
    InputLabel: 'input-label',
    InputPlaceholder: 'input-placeholder',
    Border: 'border',
    Background: 'background',
    Complete: 'complete',
    Compliant: 'compliant',
    Upcoming: 'upcoming',
    Overdue: 'overdue',
    NotCritical: 'not-critical',
    Critical: 'critical',
    Defect: 'defect',
    NotCompliant: 'not-compliant',
} as const;

export type ColorContext = (typeof COLOR_CONTEXT)[keyof typeof COLOR_CONTEXT];
