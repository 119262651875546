@let id = 'filter-' + filter().name | qpxKebabCase;

<button
    [id]="id"
    [attr.data-testid]="id"
    qpxButton="unstyled"
    mat-button
    class="filter-form-field trigger"
    [ngClass]="inputClasses()"
    [class.primary]="context() === 'primary'"
    [class.secondary]="context() === 'secondary'"
    [matMenuTriggerFor]="menu"
    [disabled]="!filter().options.length"
>
    @if (appliedAndSelectedCount() && counterWithPlaceholder()) {
        <span class="counter">{{ appliedAndSelectedCount() }} </span>
    }

    <span class="content">{{ triggerDisplayValue() }}</span>

    <div class="arrow">
        @switch (context()) {
            @case ('primary') {
                <span class="material-symbols-outlined" aria-hidden="true">
                    arrow_drop_down
                </span>
            }
            @case ('secondary') {
                <span
                    class="text-accent material-symbols-outlined"
                    aria-hidden="true"
                    >keyboard_arrow_down</span
                >
            }
        }
    </div>
</button>

<mat-menu
    #menu="matMenu"
    xPosition="after"
    [aria-labelledby]="id"
    [class]="allPanelClasses()"
    (closed)="onDropdownClosed()"
>
    <ng-template matMenuContent>
        @if (showHeader()) {
            <div
                class="dropdown__header"
                (click)="$event.stopPropagation()"
                (keydown)="$event.stopPropagation()"
            >
                <div class="row">
                    <mat-label class="font-semibold">
                        {{ selectedCount() }} selected
                    </mat-label>
                    <button
                        qpxButton
                        mat-stroked-button
                        [disabled]="!selectedCount()"
                        (click)="clearAll()"
                    >
                        Clear All
                    </button>
                </div>

                @if (enableSearch()) {
                    <div
                        class="row"
                        (click)="$event.stopPropagation()"
                        (keydown)="$event.stopPropagation()"
                    >
                        <mat-form-field
                            class="w-100"
                            qpxInput="search"
                            subscriptSizing="dynamic"
                        >
                            <mat-icon matPrefix>search</mat-icon>

                            <input
                                [id]="id + '-search'"
                                type="text"
                                name="search"
                                spellcheck="false"
                                autocomplete="off"
                                [placeholder]="searchPlaceholder()"
                                matInput
                                maxlength="50"
                                [(ngModel)]="search"
                                [ngModelOptions]="{ standalone: true }"
                            />

                            @if (search().length) {
                                <button
                                    type="button"
                                    matSuffix
                                    qpxButton
                                    mat-icon-button
                                    aria-label="Clear"
                                    (click)="clearSearch()"
                                >
                                    <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </div>
                }
            </div>
        }

        <ul
            class="dropdown__options"
            [class.with-search]="enableSearch()"
            (click)="$event.stopPropagation()"
            (keydown)="$event.stopPropagation()"
        >
            @if (allOption(); as allOption) {
                <li>
                    <mat-checkbox
                        qpx
                        [id]="id + '-option-all'"
                        [checked]="isOptionSelected(allOption.id)"
                        (change)="onToggleAll($event.checked)"
                    >
                        <span>{{ allOption.name }}</span>
                    </mat-checkbox>
                </li>
            }

            @if (noneOption(); as noneOption) {
                <li>
                    <mat-checkbox
                        qpx
                        [id]="id + '-option-none'"
                        [checked]="isOptionSelected(noneOption.id)"
                        (change)="onToggleNone($event.checked)"
                    >
                        <span>{{ noneOption.name }}</span>
                    </mat-checkbox>
                </li>
            }

            @for (item of filteredOptions(); track item.id) {
                <li>
                    <mat-checkbox
                        qpx
                        [id]="id + '-option-' + item.id"
                        [checked]="isOptionSelected(item.id)"
                        (change)="onToggleOrdinary(item.id, $event.checked)"
                    >
                        {{ item.name }}
                    </mat-checkbox>
                </li>
            }
        </ul>

        @if (!applyOnClose()) {
            <div
                class="dropdown__footer"
                (click)="$event.stopPropagation()"
                (keydown)="$event.stopPropagation()"
            >
                <button
                    qpxButton
                    mat-stroked-button
                    (click)="applyFiltersAndCloseDropdown()"
                >
                    <mat-icon class="qpx-icon-sm"> done </mat-icon>
                    <span>Apply</span>
                </button>
            </div>
        }
    </ng-template>
</mat-menu>
