import { NgComponentOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    inject,
    signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DialogResult, DialogService, IDialogData } from '@quipex/shared/data';
import { LoaderComponent } from '../loader.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'qpx-dialog',
    templateUrl: './dialog.component.html',
    imports: [
        NgComponentOutlet,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        LoaderComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent {
    protected readonly dialogData: IDialogData<any> = inject(MAT_DIALOG_DATA);
    private readonly dialogRef: MatDialogRef<DialogComponent, DialogResult> =
        inject(MatDialogRef);
    private readonly dialogService = inject(DialogService);

    protected readonly isProcessing = signal(false);
    protected readonly canConfirm = signal(false);

    constructor() {
        this.canConfirm.set(this.dialogData.confirmEnabled ?? false);

        this.handleDialogEvents();
    }

    private handleDialogEvents(): void {
        this.dialogService.isProcessing$
            .pipe(takeUntilDestroyed())
            .subscribe((isProcessing) => this.isProcessing.set(isProcessing));

        this.dialogService.canConfirm$
            .pipe(takeUntilDestroyed())
            .subscribe((canConfirm) => {
                this.canConfirm.set(canConfirm);
            });

        this.dialogService.close$
            .pipe(takeUntilDestroyed())
            .subscribe((isConfirm) => this.closeDialog(isConfirm));

        this.dialogService.confirmLabelChange$
            .pipe(takeUntilDestroyed())
            .subscribe((label) => (this.dialogData.submitButtonLabel = label));

        this.dialogService.confirmIconChange$
            .pipe(takeUntilDestroyed())
            .subscribe((icon) => (this.dialogData.submitButtonIcon = icon));

        this.dialogService.cancelButtonVisible$
            .pipe(takeUntilDestroyed())
            .subscribe({
                next: (visible) =>
                    (this.dialogData.hideCancelButton = !visible),
            });

        this.dialogService.confirmButtonTheme$
            .pipe(takeUntilDestroyed())
            .subscribe({
                next: (theme) => (this.dialogData.submitButtonTheme = theme),
            });

        this.dialogService.dialogTitle$.pipe(takeUntilDestroyed()).subscribe({
            next: (title) => (this.dialogData.dialogTitle = title),
        });
    }

    protected onCancel(): void {
        this.dialogService.requestCancel();
    }

    protected onConfirm(): void {
        this.dialogService.requestConfirm();
    }

    private closeDialog(isConfirm: DialogResult): void {
        this.dialogRef.close(isConfirm);
    }
}
