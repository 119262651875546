import { SortOrder } from '../enums.model';
import { BaseQuery, PaginatedResponse } from '../typings/misc.interface';

export enum OrganizationTypeEnum {
    Admin = 5,
    Owner = 10,
    MaintenanceContractor = 20,
    Builder = 30,
}

export enum AccessGrantedOrganizationsSortFieldEnum {
    None = -1,
    Name = 10,
    Status = 20,
}

export interface IGetAccessGrantedOrganizationsRequestDTO extends BaseQuery {
    searchTerm: string;
    sortOrder: SortOrder;
    sortField: AccessGrantedOrganizationsSortFieldEnum;
}

export interface IGetAccessGrantedOrganizationDetailsRequestDTO {
    grantId: string;
    searchTerm: string;
    pageSize: number;
    pageNo: number;
    pageIndex: number; // UI only
}

export interface IAccessOrganisation {
    id: number;
    name: string;
    identifier: string;
}

export interface IAccessOrganisationSearch {
    id: string;
    companyName: string;
    isGranted: boolean;
    organizationType: OrganizationTypeEnum;
}

export interface IGrantedOrganisationNames {
    id: string;
    name: string;
    isGranted: boolean;
    organizationType: OrganizationTypeEnum;
}

interface IGrantExistingOrganisationBuildingRequest {
    buildingIds: number[];
    existingOrganizationId: string;
    newOrganizationName?: string | null;
}

interface IGrantNewOrganisationBuildingRequestNew {
    buildingIds: number[];
    existingOrganizationId?: string | null;
    newOrganizationName: string;
}

export type IGrantOrganisationBuildingAccessRequest =
    | IGrantExistingOrganisationBuildingRequest
    | IGrantNewOrganisationBuildingRequestNew;

export interface IEditOrganisationBuildingAccessRequest {
    grantedBuildings: string[];
    orgId: string;
}

export enum ComplianceStatus {
    NotCompliant = 1,
    Expiring,
    Compliant,
}

export interface IGrantedOrganisationItems {
    id: string;
    name: string;
    isActive: boolean;
    complianceStatus: ComplianceStatus;
}

export type IGetGrantedOrganisationsResponse =
    PaginatedResponse<IGrantedOrganisationItems>;

export interface BaseComplianceRequirementDetails {
    id: string;
    complianceStatus: ComplianceStatus;
    /** @type {date-time} */
    expiryDate?: string;
    documentId?: string;
}

export interface ComplianceRequirementDetails
    extends BaseComplianceRequirementDetails {
    name: string;
    hasExpiry: boolean;
    isCustom: boolean;
}

export interface BuildingAccess {
    id: string;
    name: string;
    isGranted: boolean;
}

export interface GetOrganizationAccessDetailsResponse {
    organizationId: string;
    name: string;
    isActive: boolean;
    orgComplianceStatus: ComplianceStatus;
    employeesComplianceStatus: ComplianceStatus;
    orgComplianceRequirements: ComplianceRequirementDetails[];
    employeesComplianceRequirements: ComplianceRequirement[];
    buildings: BuildingAccess[];
    isSiteInductionEnabled: boolean;
    employeesAreActive: boolean;
}

export interface ComplianceRequirement {
    id: string;
    name: string;
    hasExpiry: boolean;
    isCustom: boolean;
}

export interface UploadComplianceDocument {
    complianceRequirementId: string;
    documentFile: File;
    expiryDate?: string | null;
}

export interface GetOrganizationComplianceRequirementsResponse {
    organizationRequirements: ComplianceRequirement[];
    employeeRequirements: ComplianceRequirement[];
}

export interface CustomComplianceRequirement {
    name: string;
    hasExpiry: boolean;
}

export interface UpdateOrganizationComplianceRequirementsRequest {
    organizationId: string;
    /** predefined organisation requirement ids to both add or keep */
    organizationRequirementIds: string[];
    /** custom organisation requirements to add or update */
    organizationNewRequirements: CustomComplianceRequirement[];
    /** predefined employee requirement ids to both add or keep */
    employeeRequirementIds: string[];
    /** custom employee requirements to add or update */
    employeeNewRequirements: CustomComplianceRequirement[];
    /** Controls whether site induction requirements are enabled for employees */
    isSiteInductionEnabled: boolean;
}

export function isComplianceRequirement(
    requirement: unknown
): requirement is ComplianceRequirement {
    return (
        typeof requirement === 'object' &&
        requirement != null &&
        'id' in requirement &&
        'name' in requirement &&
        'hasExpiry' in requirement &&
        'isCustom' in requirement &&
        !('complianceStatus' in requirement) &&
        !('expiryDate' in requirement) &&
        !('documentId' in requirement)
    );
}

export function isComplianceRequirementDetails(
    requirement: unknown
): requirement is ComplianceRequirementDetails {
    return (
        typeof requirement === 'object' &&
        requirement != null &&
        'id' in requirement &&
        'name' in requirement &&
        'hasExpiry' in requirement &&
        'isCustom' in requirement &&
        'complianceStatus' in requirement
    );
}
