import { NgTemplateOutlet } from '@angular/common';
import {
    Component,
    inject,
    input,
    computed,
    output,
    signal,
    TemplateRef,
    ChangeDetectionStrategy,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ReviewBuildingDetailsComponent } from '../../review-building-details/review-building-details.component';
import {
    AzureFunctionApiService,
    BuildingDocuments,
    DialogService,
    DocDto,
    DocumentApiService,
    DocumentTypeEnum,
    IOCDetailsExtraction,
    MonitoringService,
    SharedService,
    UserStore,
} from '@quipex/shared/data';
import { filter, firstValueFrom, mergeWith } from 'rxjs';
import { FileListComponent } from '../file-list/file-list.component';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { DocumentLoaderComponent } from '../upload-files/document-loading-screen/document-loading-screen';
import { BulkUploadFilesService } from './bulk-upload-files.service';

const FILE_UPLOAD_ERROR_MESSAGE = {
    Preparation: 'Document preparation failed',
    Upload: 'Document upload failed',
} as const;

const LOG_EVENT_PREFIX = 'Document Upload';

@Component({
    selector: 'qpx-bulk-upload-files',
    templateUrl: './bulk-upload-files.component.html',
    styleUrls: ['./bulk-upload-files.component.scss'],
    imports: [
        NgTemplateOutlet,
        MatFormFieldModule,
        MatChipsModule,
        MatDialogModule,
        MatDividerModule,
        MatIconModule,
        MatButtonModule,
        MatSnackBarModule,
        DocumentLoaderComponent,
        FileUploadComponent,
        FileListComponent,
    ],
    providers: [BulkUploadFilesService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkUploadFilesComponent {
    private readonly fileProcessing = inject(BulkUploadFilesService);
    private readonly sharedService = inject(SharedService);
    private readonly documentService = inject(DocumentApiService);
    private readonly azFunctionService = inject(AzureFunctionApiService);
    private readonly userStore = inject(UserStore);
    private readonly snackBar = inject(MatSnackBar);
    private readonly dialog = inject(MatDialog);
    public readonly dialogService = inject(DialogService);
    private readonly loggingService = inject(MonitoringService);

    public readonly _buildingId = input('', {
        // eslint-disable-next-line @angular-eslint/no-input-rename
        alias: 'buildingId',
    });
    private readonly _buildingId$ = toObservable(this._buildingId).pipe(
        mergeWith(
            this.sharedService.queryParams$.pipe(
                filter((params) => typeof params === 'string')
            )
        )
    );
    protected readonly buildingId = toSignal(this._buildingId$, {
        initialValue: '',
    });

    protected readonly files = signal<DocDto[]>([]);
    /** public-facing only, do not use internally */
    public readonly _files = input<DocDto[], DocDto[]>([], {
        transform: (value) => {
            this.files.set(value);
            return value;
        },
        // eslint-disable-next-line @angular-eslint/no-input-rename
        alias: 'files',
    });

    public readonly viewType = input<
        'upload-with-file-list' | 'upload-with-tags' | 'tags-only'
    >('upload-with-tags');
    public readonly shouldCheckForDuplicates = input(true);
    /** template for loading state */
    public readonly loadingTpl = input<TemplateRef<any> | null>(null);
    public readonly showBuildingReviewScreen = input(false);
    /** allow classification of documents */
    public readonly canClassifyDocuments = input(true);

    public readonly filesSelectionChange = output<DocDto[]>();
    public readonly uploadFinished = output<void>();
    public readonly isProcessingChange = output<boolean>();
    public readonly canConfirmChange = output<boolean>();
    public readonly sharePointSelected = output<void>();

    protected readonly isLoading = signal(false);

    private readonly me$ = this.userStore.select('me');
    private readonly me = toSignal(this.me$);
    private readonly _currentUserId = computed(
        () => this.me()?.internalId ?? 0
    );

    protected onFileRemove(fileId: string): void {
        const index = this.files().findIndex((file) => file.id === fileId);
        if (index !== -1) {
            this.files.update((files) =>
                files.filter((_, idx) => idx !== index)
            );
            this.filesSelectionChange.emit(this.files());
        }
    }

    protected onSharePointClick(): void {
        this.sharePointSelected.emit();
        this.dialogService.requestConfirm$.next();
    }

    protected fileProcessingChange(processing: boolean): void {
        this.isLoading.set(!processing);
    }

    protected async onFileUploadListChanged(files: File[]): Promise<void> {
        if (!files?.length) {
            this.isLoading.set(false);
            return;
        }

        try {
            this.setLoadingState(true);

            const processedFiles = await this.fileProcessing.processFiles(
                files,
                this.canClassifyDocuments()
            );

            this.updateFiles(processedFiles);
            await this.checkForOccupancyPermits(processedFiles);
        } catch (error) {
            console.error('Error processing files:', error);
            this.showToast('danger', 'Error processing files');
        } finally {
            this.setLoadingState(false);
        }
    }

    protected onFileListChange(): void {
        // files object has been altered inside file-list component
        this.filesSelectionChange.emit(this.files());
    }

    public async handleUploadDocuments(): Promise<void> {
        this.isProcessingChange.emit(true);

        const files = this.files();

        this.loggingService.logEvent(
            `${LOG_EVENT_PREFIX}: before calling handleUploadDocuments - bulk upload modal`,
            files
        );

        try {
            // Step 1: Prepare documents
            const documents = await this.prepareDocumentsWithValidation(files);

            // Step 2: Upload documents
            await this.uploadDocuments(documents);

            // Step 3: Run indexer after successful upload (do not await)
            this.runSearchIndexer();
        } catch (err) {
            const { message, stack, customLogEvent } =
                this.extractFromError(err);
            this.loggingService.logEvent(customLogEvent, {
                error: message,
                stack,
            });

            console.error(err);

            this.showToast('danger', 'File(s) upload failed');
        } finally {
            this.isProcessingChange.emit(false);
        }
    }

    private setLoadingState(isLoading: boolean): void {
        this.isLoading.set(isLoading);
        this.canConfirmChange.emit(!isLoading);
    }

    private updateFiles(newFiles: DocDto[]): void {
        this.files.update((current) => [...current, ...newFiles]);
        this.filesSelectionChange.emit(this.files());
    }

    private async checkForOccupancyPermits(files: DocDto[]): Promise<void> {
        if (!this.showBuildingReviewScreen()) return;

        const occupancyPermits = files.filter(
            (file) => file.documentType === DocumentTypeEnum.OccupancyPermit
        );

        for (const file of occupancyPermits) {
            await this.extractAndReviewOCDetails(file);
        }
    }

    private async extractAndReviewOCDetails(file: DocDto): Promise<void> {
        try {
            const details = await firstValueFrom(
                this.documentService.extractOCDetails(file.file)
            );

            if (this.hasValidDetails(details)) {
                this.openReviewBuildingDetailsModal(details);
            }
        } catch (error) {
            console.error('Error extracting OC details:', error);
        }
    }

    private hasValidDetails(details: IOCDetailsExtraction): boolean {
        return Object.values(details).some(
            (value) =>
                value !== '' &&
                value !== null &&
                value !== undefined &&
                !(Array.isArray(value) && value.length === 0)
        );
    }

    private async prepareDocumentsWithValidation(
        files: DocDto[]
    ): Promise<BuildingDocuments> {
        try {
            return this.documentService.prepareDocumentsForUpload(
                files,
                this.buildingId(),
                this._currentUserId()
            );
        } catch (err) {
            const error = err instanceof Error ? err : new Error(`${err}`);
            error.message = `${FILE_UPLOAD_ERROR_MESSAGE.Preparation}: ${error.message}`;
            throw error;
        }
    }

    private async uploadDocuments(documents: BuildingDocuments): Promise<void> {
        try {
            await firstValueFrom(
                this.documentService.uploadDocument(documents)
            );
            await firstValueFrom(this.azFunctionService.runSearchIndexer());

            this.showToast(
                'success',
                'File(s) has been uploaded successfully!'
            );
            this.uploadFinished.emit();
            this.files.set([]);
        } catch (err) {
            const error = err instanceof Error ? err : new Error(`${err}`);
            error.message = `${FILE_UPLOAD_ERROR_MESSAGE.Upload}: ${error.message}`;
            throw error;
        }
    }

    private async runSearchIndexer(): Promise<void> {
        try {
            await firstValueFrom(this.azFunctionService.runSearchIndexer());
        } catch (err) {
            const { message, stack } = this.extractFromError(err);
            const customLogEvent = `${LOG_EVENT_PREFIX}: search indexer failed`;
            this.loggingService.logEvent(customLogEvent, {
                error: message,
                stack,
            });

            // Don't throw here - indexer failure shouldn't invalidate successful upload
            console.warn(`${customLogEvent}:`, err);
        }
    }

    private extractFromError(error: unknown) {
        let message = 'Unknown error occurred';
        let stack: string | undefined = undefined;
        let customLogEvent = `${LOG_EVENT_PREFIX}: error`;

        if (error instanceof Error) {
            message = error.message;
            stack = error.stack;
        }

        if (message.startsWith(FILE_UPLOAD_ERROR_MESSAGE.Preparation)) {
            customLogEvent = `${LOG_EVENT_PREFIX}: preparation failed`;
        } else if (message.startsWith(FILE_UPLOAD_ERROR_MESSAGE.Upload)) {
            customLogEvent = `${LOG_EVENT_PREFIX}: upload failed`;
        }

        return {
            message,
            stack,
            error,
            customLogEvent,
        };
    }

    private showToast(panelClass: string, title: string): void {
        const icon = panelClass === 'success' ? 'check_circle' : 'error';
        this.snackBar.open(title, icon, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: panelClass,
            duration: 1500,
        });
    }

    private openReviewBuildingDetailsModal(
        ocDetailsExtraction: IOCDetailsExtraction
    ): void {
        this.dialog.open(ReviewBuildingDetailsComponent, {
            disableClose: true,
            panelClass: 'qpx-dialog',
            data: {
                buildingId: this.buildingId,
                ocDetailsExtraction,
            },
        });
    }
}
