export enum AssetCategoryEnum {
    Operational = 1,
    Ancillary,
    NonOperational,
    Administered,
}

export enum AssetCategoryNamesEnum {
    Operational = 'Operational',
    Ancillary = 'Ancillary',
    NonOperational = 'Non-operational',
    Administered = 'Administered',
}

export enum AssetCriticalityEnum {
    NoImpact = 1,
    Low,
    Medium,
    High,
    Critical,
}

export enum AssetCriticalityNamesEnum {
    NoImpact = 'No impact',
    Low = 'Low',
    Medium = 'Medium',
    High = 'High',
    Critical = 'Critical',
}

export enum AssetConditionEnum {
    Failed = 1,
    BadlyDeteriorated,
    AverageCondition,
    MinorDefects,
    NoDefects,
}

export enum AssetConditionNamesEnum {
    Failed = 'Failed',
    BadlyDeteriorated = 'Badly deteriorated',
    AverageCondition = 'Average condition',
    MinorDefects = 'Minor defects',
    NoDefects = 'No defects',
}

export enum AssetRegisterReportingYearsEnum {
    CALENDAR = 1,
    FINANCIAL = 2,
}

export enum AssetRegisterReportingYearEnum {
    CALENDAR = 'Calendar',
    FINANCIAL = 'Financial',
}

export interface IAssetRegister {
    id: string;
    name: string;
    category: AssetCategoryEnum;
    criticality: AssetCriticalityEnum;
    condition: AssetConditionEnum;
    endOfLifeDate: Date;
    expectedReplacementCost: number;
    categoryName: AssetCategoryNamesEnum | string; // UI only
    criticalityName: AssetCriticalityNamesEnum | string; // UI only
    conditionName?: AssetConditionNamesEnum | string; // UI only
    showDateAlert: boolean; // UI only
}

export interface IGeAssetRegister {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    pageIndex: number;
    totalCount: number;
    totalPages: number;
    items: IAssetRegister[];
}

export interface IAssetRegistersDocument {
    documentId: string;
    documentName: string;
}

export interface IGeAssetRegisterResponse {
    reportingYear: AssetRegisterReportingYearsEnum;
    assetDetails: IGeAssetRegister;
}

export interface IAssetRegistersDetails {
    id: string;
    name: string;
    category: AssetCategoryEnum;
    criticality: AssetCriticalityEnum;
    condition: AssetConditionEnum;
    endOfLifeDate: Date;
    description: string;
    manufacturer: string;
    model: string;
    serialNo: string;
    warranty: string;
    dateOfPurchase: Date;
    costOfPurchase: number;
    location: string;
    expectedReplacementCost: number;
    documents: IAssetRegistersDocument[];
}

export interface IAssetName {
    id: number;
    assetName: string;
}

export interface IAssetRegisterPostModel {
    buildingId: string;
    name: string;
    category: AssetCategoryEnum;
    criticality: AssetCriticalityEnum;
    condition: AssetConditionEnum;
    endOfLifeDate: Date | null;
    description: string;
    manufacturer: string;
    model: string;
    serialNo: string;
    warranty: string;
    dateOfPurchase: Date | null;
    costOfPurchase: number;
    location: string;
    expectedReplacementCost: number;
    documents: File[];
}

export interface IAssetRegisterPutModel {
    assetId: string;
    name: string;
    category: AssetCategoryEnum;
    criticality: AssetCriticalityEnum;
    condition: AssetConditionEnum;
    endOfLifeDate: Date | null;
    description: string;
    manufacturer: string;
    model: string;
    serialNo: string;
    warranty: string;
    dateOfPurchase: Date | null;
    costOfPurchase: number;
    location: string;
    expectedReplacementCost: number;
    existingDocumentIds: number[];
    newDocuments: File[];
}
