import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiServiceBase } from '../api-service-base';
import {
    IBuildingName,
    IGetGrantedBuildingsResponse,
    IGetGrantedOrganisationsResponse,
    IOrganisationGrantedUser,
    IAccessOrganisation,
    IAccessOrganisationSearch,
    IGrantOrganisationBuildingAccessRequest,
    IEditOrganisationBuildingAccessRequest,
    IGetAccessGrantedOrganizationsRequestDTO,
    GetOrganizationAccessDetailsResponse,
    GetOrganizationComplianceRequirementsResponse,
    UpdateOrganizationComplianceRequirementsRequest,
    UploadComplianceDocument,
    CreateContractorEmployeeRequest,
    UserModelDetailsDTO,
    GetPaginatedContractorEmployeesRequest,
    GetPaginatedContractorEmployeesResponse,
    GetContractorEmployeeDetailsResponse,
    EditContractorEmployeeAccessRequest,
    UploadInductionDocument,
} from '../models';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class AccessApiService extends ApiServiceBase {
    private readonly apiAccessBase = `${this.appConfig.apiBase}/api/accesses`;

    getOrganizations(buildingId: string): Observable<IAccessOrganisation[]> {
        const url = `${this.apiAccessBase}/buildings/${buildingId}/granted-organizations`;
        return this.http.get<IAccessOrganisation[]>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getAccessOrganizationsNames(
        searchTerm: string
    ): Observable<IAccessOrganisationSearch[]> {
        const url = `${this.apiAccessBase}/organizations/${searchTerm}`;
        return this.http.get<IAccessOrganisationSearch[]>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getGrantedOrganizationsNames(orgId: string): Observable<IBuildingName[]> {
        const url = `${this.apiAccessBase}/granted-organization-buildings-names/${orgId}`;
        return this.http.get<IBuildingName[]>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getGrantedOrganizations(
        _params: IGetAccessGrantedOrganizationsRequestDTO
    ): Observable<IGetGrantedOrganisationsResponse> {
        const url = `${this.apiAccessBase}/organizations`;
        const params = new HttpParams({ fromObject: { ..._params } });
        return this.http
            .get<IGetGrantedOrganisationsResponse>(url, { params })
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getOrganizationAccessDetails(orgId: string) {
        const url = `${this.apiAccessBase}/organizations/${orgId}/details`;
        return this.http.get<GetOrganizationAccessDetailsResponse>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    uploadInductionDocument(postBody: UploadInductionDocument) {
        const url = `${this.apiAccessBase}/organizations/contractor-employees/site-induction-document`;

        const formData = new FormData();
        formData.append('employeeId', postBody.employeeId);
        formData.append('documentFile', postBody.documentFile);
        formData.append('expiryDate', postBody.expiryDate.toString());

        postBody.buildingIds.forEach((buildingId) => {
            formData.append('buildingIds', buildingId);
        });

        return this.http.post<boolean>(url, formData).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    uploadComplianceDocument(postBody: UploadComplianceDocument) {
        const complianceRequirementId = postBody.complianceRequirementId;
        const url = `${this.apiAccessBase}/compliance-requirements/${complianceRequirementId}/document`;

        let params: HttpParams | undefined = undefined;

        const formData = new FormData();
        formData.append('complianceRequirementId', complianceRequirementId);
        formData.append('documentFile', postBody.documentFile);

        if (postBody.expiryDate) {
            params = new HttpParams({
                fromObject: { expiryDate: postBody.expiryDate },
            });
        }

        return this.http.post<boolean>(url, formData, { params }).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getGrantedBuildings(
        grantId: string
    ): Observable<IGetGrantedBuildingsResponse> {
        const url = `${this.apiAccessBase}/granted/${grantId}/buildings`;
        return this.http.get<IGetGrantedBuildingsResponse>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getGrantedUsers(
        buildingId: string,
        orgId: string
    ): Observable<IOrganisationGrantedUser[]> {
        const url = `${this.apiAccessBase}/users/${buildingId}/${orgId}`;
        return this.http.get<IOrganisationGrantedUser[]>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    updateGrantedBuildingAccess(
        payload: IEditOrganisationBuildingAccessRequest
    ): Observable<boolean> {
        const url = `${this.apiAccessBase}/organizations/building-access-grants`;
        return this.http.put<boolean>(url, payload).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    grantOrganisationBuildingAccess(
        postBody: IGrantOrganisationBuildingAccessRequest
    ): Observable<boolean> {
        const url = `${this.apiAccessBase}/organizations/building-access-grants`;
        return this.http.post<boolean>(url, postBody).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getOrganisationComplianceRequirements(orgId: string) {
        const url = `${this.apiAccessBase}/organizations/${orgId}/compliance-requirements`;
        return this.http
            .get<GetOrganizationComplianceRequirementsResponse>(url)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    updateOrganisationComplianceRequirements(
        putBody: UpdateOrganizationComplianceRequirementsRequest
    ) {
        const orgId = putBody.organizationId;
        const url = `${this.apiAccessBase}/organizations/${orgId}/compliance-requirements`;
        return this.http.put<boolean>(url, putBody).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    createContractorEmployee(postBody: CreateContractorEmployeeRequest) {
        const orgId = postBody.organizationId;
        const url = `${this.apiAccessBase}/organizations/${orgId}/contractor-employees`;

        return this.http.post<UserModelDetailsDTO>(url, postBody).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getContractorEmployeesList(
        getBody: GetPaginatedContractorEmployeesRequest
    ) {
        const { organizationId, ...restBody } = getBody;
        const url = `${this.apiAccessBase}/organizations/${organizationId}/contractor-employees`;

        const params = new HttpParams({ fromObject: { ...restBody } });

        return this.http
            .get<GetPaginatedContractorEmployeesResponse>(url, { params })
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getContractorEmployeeDetails(employeeId: string) {
        const url = `${this.apiAccessBase}/organizations/contractor-employees/${employeeId}`;

        return this.http.get<GetContractorEmployeeDetailsResponse>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    editContractorEmployeeDetails(
        payload: EditContractorEmployeeAccessRequest
    ) {
        const url = `${this.apiAccessBase}/organizations/contractor-employees/building-access`;

        return this.http.put<boolean>(url, payload).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    disableContractorEmployee(employeeId: string) {
        const url = `${this.apiAccessBase}/organizations/contractor-employees/${employeeId}/status`;
        return this.http.patch<boolean>(url, null).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    disableContractorCompany(organizationId: string) {
        const url = `${this.apiAccessBase}/organizations/${organizationId}/status`;
        return this.http.patch<boolean>(url, null).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }
}
