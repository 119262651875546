import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'qpxSentenceCase',
})
export class SentenceCasePipe implements PipeTransform {
    /**
     * Transforms a given string to sentence case, capitalizing only the first letter of the first word.
     *
     * Example usage in a template:
     * ```
     * {{ 'hello WORLD from ANGULAR' | qpxSentenceCase }}
     * ```
     * This will render as:
     * ```
     * Hello world from angular
     * ```
     *
     * @param value - The input string to transform.
     * @returns The transformed string in sentence case.
     */
    transform(value: string | undefined): string {
        if (typeof value !== 'string' || !value) {
            return value ?? '';
        }

        const lowerCaseValue = value.toLowerCase();
        return lowerCaseValue.charAt(0).toUpperCase() + lowerCaseValue.slice(1);
    }
}
