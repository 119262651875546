import {
    computed,
    DestroyRef,
    Directive,
    inject,
    input,
    OnInit,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { MatButton, MatIconButton } from '@angular/material/button';

type ButtonType = 'link' | 'button' | 'unstyled' | 'table';

// TODO: tie this to the CONTEXT_COLORS
type ButtonColor = 'primary' | 'warn' | 'accent';

@Directive({
    selector: 'button[qpxButton], a[qpxButton]',
    host: {
        '[class]': 'className()',
        type: 'button',
    },
})
export class QpxButtonDirective implements OnInit {
    private readonly matButtonRef = inject(MatButton, {
        host: true,
        optional: true,
    });
    private readonly matIconButtonRef = inject(MatIconButton, {
        host: true,
        optional: true,
    });
    private readonly destroyRef = inject(DestroyRef);

    /**
     * A directive property that defines the variant of the button.
     *
     * @example
     * ```html
     * <button qpxButton>Button</button>
     * ```
     *
     * @example
     * ```html
     * <a qpxButton="link">Link Button</a>
     * ```
     */
    public readonly variant = input<ButtonType, ButtonType | ''>('button', {
        alias: 'qpxButton',
        transform: (value) => value || 'button',
    });

    public readonly size = input<'md' | 'sm'>('md');

    public readonly context = input<ButtonColor>('primary');
    private readonly context$ = toObservable(this.context);

    protected readonly className = computed(() => {
        const _className = ['unstyled', 'qpx-button', this.size()];

        const usingMatButton = this.matButtonRef || this.matIconButtonRef;

        if (this.variant() === 'unstyled') {
            return _className.join(' ');
        }

        if (this.variant() !== 'table' && !usingMatButton) {
            const isSmall = this.size() === 'sm';
            _className.push(`qpx-font-button${isSmall ? '-sm' : ''}`);
        }

        if (this.variant() === 'link' || this.variant() === 'table') {
            _className.push('link');
        }

        if (!this.matButtonRef && !this.matIconButtonRef) {
            _className.push(`text-${this.context()}`);
        }

        return _className.join(' ');
    });

    ngOnInit(): void {
        this.context$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
            next: (value) => {
                if (this.matButtonRef) {
                    this.matButtonRef.color = value;
                } else if (this.matIconButtonRef) {
                    this.matIconButtonRef.color = value;
                }
            },
        });
    }
}
