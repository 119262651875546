import { computed, Directive, input } from '@angular/core';
import { COLOR_CONTEXT, ColorContext } from '@quipex/shared/helpers';

@Directive({
    selector: '[qpxModalTitle]',
    host: {
        '[class]': 'className()',
    },
})
export class QpxModalTitleDirective {
    public readonly context = input<ColorContext, ColorContext | ''>(
        COLOR_CONTEXT.Primary,
        {
            alias: 'qpxModalTitle',
            transform: (value: ColorContext | '') =>
                value || COLOR_CONTEXT.Primary,
        }
    );

    protected readonly className = computed(() => {
        return ['qpx-font-modal-title', 'm-0', `text-${this.context()}`].join(
            ' '
        );
    });
}

@Directive({
    selector: '[qpxModalHeadline]',
    host: {
        '[class]': 'className()',
    },
})
export class QpxModalHeadlineDirective {
    public readonly context = input<ColorContext, ColorContext | ''>(
        COLOR_CONTEXT.Primary,
        {
            alias: 'qpxModalHeadline',
            transform: (value: ColorContext | '') =>
                value || COLOR_CONTEXT.Primary,
        }
    );

    protected readonly className = computed(() => {
        return [
            'qpx-font-modal-headline',
            'm-0',
            `text-${this.context()}`,
        ].join(' ');
    });
}

@Directive({
    selector: '[qpxModalDescription]',
    host: {
        '[class]': 'className()',
    },
})
export class QpxModalDescriptionDirective {
    public readonly context = input<ColorContext, ColorContext | ''>(
        COLOR_CONTEXT.Primary,
        {
            alias: 'qpxModalDescription',
            transform: (value: ColorContext | '') =>
                value || COLOR_CONTEXT.Primary,
        }
    );

    protected readonly className = computed(() => {
        return [
            'qpx-font-modal-description',
            'm-0',
            `text-${this.context()}`,
        ].join(' ');
    });
}
