import { computed, Directive, input } from '@angular/core';

type InputType = 'input' | 'search' | 'select';

@Directive({
    selector: 'mat-form-field[qpxInput]',
    host: {
        '[class]': 'className()',
    },
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class QpxFormFieldInput {
    public readonly variant = input<InputType, InputType | ''>('input', {
        alias: 'qpxInput',
        transform: (value) => value || 'input',
    });

    protected readonly className = computed(() => {
        const _classNames = ['qpx-input', this.variant()];
        return _classNames.join(' ');
    });
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'input[matInput]',
    host: {
        '[attr.id]': 'id()',
    },
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class QpxInput {
    id = input.required<string>();
}
