import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    ElementRef,
    inject,
    input,
    linkedSignal,
    OnInit,
    output,
    signal,
    untracked,
    viewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import {
    Filter,
    FilterOptionId,
    FilterOption,
    AppliedFilterOptionId,
} from './filter';
import {
    QpxButtonDirective,
    QpxFormFieldDirectives,
} from '@quipex/shared/directives';
import { KebabCasePipe } from '@quipex/shared/pipes';
import {
    takeUntilDestroyed,
    toObservable,
    toSignal,
} from '@angular/core/rxjs-interop';
import { combineLatestWith, distinctUntilChanged, filter, map } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { DEBOUNCE_DELAY, validateSearchInput } from '@quipex/shared/helpers';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { QpxFormFieldComponents } from '../form-field';

/**
 * Type for initial filter options that can be either name strings, `FilterOptionIds` or partial objects with name/id of `FilterOption`
 */
type InitialAppliedOption<U extends FilterOptionId = FilterOptionId> =
    | string
    | U
    | Partial<FilterOption<U>>;

/**
 * Type guard to check if value is a partial filter option with id and/or name
 * @param value - Value to check
 * @returns true if value is an object with id or name properties
 */
function isPartialFilterOption<U extends FilterOptionId = FilterOptionId>(
    value: unknown
): value is Partial<FilterOption<U>> {
    return (
        typeof value === 'object' &&
        value !== null &&
        ('id' in value || 'name' in value)
    );
}

@Component({
    selector: 'qpx-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
    imports: [
        NgClass,
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatSelectModule,
        KebabCasePipe,
        QpxButtonDirective,
        QpxFormFieldDirectives,
        QpxFormFieldComponents,
        MatInputModule,
        MatMenuModule,
        MatCheckboxModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterComponent<
    T extends string = string,
    U extends FilterOptionId = FilterOptionId,
> implements OnInit
{
    private readonly destroyRef = inject(DestroyRef);

    /** filter name and options */
    public readonly filter = input.required<Filter<T, U>>();

    /** Displays a count of selected items in the trigger button (aka input) */
    public readonly counterWithPlaceholder = input<boolean>(false);

    /**
     * Displays a header row in the dropdown
     * which contains selected items count and clear filters button
     */
    public readonly showHeader = input<boolean>(true);

    /** CSS classes applied to the trigger button (aka input) */
    public readonly inputClasses = input<string[]>([]);

    public readonly panelWidth = input<'auto' | 'match'>('match');
    private readonly panelWidth$ = toObservable(this.panelWidth);

    /** CSS classes applied to the dropdown panel */
    public readonly panelClasses = input<string, string[]>('filter-menu', {
        transform: (value) => {
            return value.join(' ');
        },
    });
    protected readonly allPanelClasses = toSignal(
        toObservable(this.panelClasses).pipe(
            combineLatestWith(this.panelWidth$),
            map(([panel, width]) => {
                const auto = width === 'auto' && 'dropdown-auto-width';
                return [panel, auto].filter(Boolean).join(' ');
            })
        ),
        { initialValue: 'filter-menu' }
    );

    /** Display content in the trigger button (aka input) */
    public readonly placeholder = input('Select');

    /** When `true`, return "all" option id if applied via `appliedChange` */
    public readonly returnAllOption = input(false);

    /** When `true`, return "none" option id if applied via `appliedChange` */
    public readonly returnNoneOption = input(true);

    /** When `true`, allows searching of filter items */
    public readonly enableSearch = input(false);

    /** Placeholder text for the conditionally-rendered search input */
    public readonly searchPlaceholder = input<string>('Search');

    /** 'Primary' is the "dark" option */
    public readonly context = input<'primary' | 'secondary'>('primary');

    /**
     * Selected options will automatically be applied on closing of the dropdown.
     * When enabled the footer will not be visible.
     */
    public readonly applyOnClose = input(false);

    /** When `true`, the "none" option is treated as a special case (like "all"), otherwise it's treated as a regular option. Defaults to `false`. */
    public readonly isNoneOptionSpecial = input(false);

    /**
     * Input property that accepts an array of
     * filter names or ids or partial filter options
     * to restore previously selected filters.
     *
     * @example
     * ```typescript
     * // In parent component
     * initialAppliedOptions = ['Building A', 'Building B'];
     * OR
     * initialAppliedOptions = [id(A), id(B)];
     * OR
     * initialAppliedOptions = [{ id: id(A) }, { id: id(B) }];
     * OR
     * initialAppliedOptions = [{name: 'Building A' }, { name: 'Building B' }];
     *
     * // In template
     * <qpx-filter [initialAppliedOptions]="initialAppliedOptions" />
     * ```
     */
    public readonly initialAppliedOptions = input<
        InitialAppliedOption[] | null
    >(null);
    private readonly initialAppliedOptions$ = toObservable(
        this.initialAppliedOptions
    );

    /**
     * Event emitter that fires when filters are applied.
     * Emits an array of applied filter ids.
     *
     * @event
     * @template U The type of the filter model
     */
    public readonly appliedChange = output<AppliedFilterOptionId<U>[]>();

    protected readonly menuTriggerRef = viewChild<unknown, MatMenuTrigger>(
        MatMenuTrigger,
        {
            read: MatMenuTrigger,
        }
    );
    protected readonly menuTriggerEl = viewChild<
        unknown,
        ElementRef<HTMLButtonElement>
    >(MatMenuTrigger, {
        read: ElementRef,
    });

    protected readonly selectedOptionIds = signal<Set<U>>(new Set());
    protected readonly appliedAndSelectedOptionIds = signal<Set<U>>(new Set());

    /** the "all" option */
    public readonly allOption = input<FilterOption<U> | null>(null);

    /** the "none" option */
    public readonly noneOption = input<FilterOption<U> | null>(null);

    protected readonly filteredOptions = linkedSignal({
        source: () => ({
            filter: this.filter,
            allOption: this.allOption,
            noneOption: this.noneOption,
        }),
        computation: ({ filter, allOption, noneOption }) => {
            const allId = allOption()?.id;
            const noneId = noneOption()?.id;
            const isNoneSpecial = this.isNoneOptionSpecial();
            const noSpecialValue = (id: U) =>
                id !== allId && (!isNoneSpecial || id !== noneId);
            return filter().options.filter((option) =>
                noSpecialValue(option.id)
            );
        },
    });

    protected readonly search = signal('');
    private readonly search$ = toObservable(this.search);

    /** Displays count + placeholder OR joined values of applied items */
    protected readonly triggerDisplayValue = computed(() => {
        const appliedIds = this.appliedAndSelectedOptionIds();

        if (!appliedIds.size || this.counterWithPlaceholder()) {
            return this.placeholder();
        }

        const allOption = untracked(this.allOption);
        const noneOption = untracked(this.noneOption);
        const isNoneSpecial = untracked(this.isNoneOptionSpecial);
        const options = [...this.filter().options];

        if (allOption !== null) {
            options.push(allOption);
        }

        if (noneOption !== null) {
            options.push(noneOption);
        }

        const sanitisedAllId = Number(allOption?.id);
        const sanitisedNoneId = Number(noneOption?.id);
        const noSpecialNumber = (id: number) =>
            id !== sanitisedAllId &&
            !Number.isNaN(id) &&
            (!isNoneSpecial || id !== sanitisedNoneId);
        const validAppliedIds = [...appliedIds].filter((id) => {
            const sanitizedNumber = Number(id);
            return noSpecialNumber(sanitizedNumber);
        });

        const newPlaceholder = options
            .filter((option) => validAppliedIds.includes(option.id))
            .map((item) => item.name)
            .filter(Boolean)
            .join(', ');
        return newPlaceholder;
    });

    /** Count of applied options that appears in the trigger button (aka input) */
    protected readonly appliedAndSelectedCount = computed(() => {
        const appliedIds = this.appliedAndSelectedOptionIds();
        let totalSelected = appliedIds.size;

        const allId = untracked(this.allOption)?.id;
        const noneId = untracked(this.noneOption)?.id;
        const isNoneSpecial = untracked(this.isNoneOptionSpecial);

        const hasAllOption =
            allId !== undefined ? appliedIds.has(allId) : false;
        const hasSpecialNoneOption =
            isNoneSpecial && noneId !== undefined
                ? appliedIds.has(noneId)
                : false;

        if (hasAllOption) {
            totalSelected = totalSelected - 1;
        }
        if (hasSpecialNoneOption) {
            totalSelected = totalSelected - 1;
        }

        return totalSelected;
    });

    /** Count of selected options that appears in the dropdown */
    protected readonly selectedCount = computed(() => {
        const selectedIds = this.selectedOptionIds();
        let totalSelected = selectedIds.size;

        const allId = untracked(this.allOption)?.id;
        const noneId = untracked(this.noneOption)?.id;
        const isNoneSpecial = untracked(this.isNoneOptionSpecial);

        const hasAllOption =
            allId !== undefined ? selectedIds.has(allId) : false;
        const hasSpecialNoneOption =
            isNoneSpecial && noneId !== undefined
                ? selectedIds.has(noneId)
                : false;

        if (hasAllOption) {
            totalSelected = totalSelected - 1;
        }
        if (hasSpecialNoneOption) {
            totalSelected = totalSelected - 1;
        }

        return totalSelected;
    });

    ngOnInit(): void {
        this.setupWithInitialAppliedOptions();
        this.onSearch();
        this.forcePanelToMatchTriggerWidth();
    }

    /**
     * Initializes filter selections based on `initialAppliedOptions` input.
     * Matches items to restore against the available filter options
     * and both applies and selects to matching items.
     *
     * @remarks
     * - Updates both current selection and applied filters states
     * - Early exits if no filters to restore or no filter items available
     */
    private setupWithInitialAppliedOptions() {
        this.initialAppliedOptions$
            .pipe(
                distinctUntilChanged(),
                filter(
                    (restore) =>
                        (this.isNoneOptionSpecial() || !!restore?.length) &&
                        !!this.filter().options.length
                ),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((filtersToRestore) => {
                const filterArray = this.filter().options;
                const itemsToApply = filterArray.filter((filterItem) => {
                    return (filtersToRestore ?? []).some((restoreItem) => {
                        if (!isPartialFilterOption(restoreItem)) {
                            // Match by name or id if restoreItem is primitive
                            const isNameString =
                                typeof restoreItem === 'string';
                            return (
                                (isNameString &&
                                    filterItem.name === restoreItem) ||
                                filterItem.id === restoreItem
                            );
                        } else if (
                            restoreItem.name === undefined &&
                            restoreItem.id === undefined
                        ) {
                            return false;
                        } else {
                            // Match by name and/or id if restoreItem is object
                            const nameMatch = restoreItem.name
                                ? filterItem.name === restoreItem.name
                                : true;
                            const idMatch =
                                restoreItem.id !== undefined
                                    ? filterItem.id === restoreItem.id
                                    : true;
                            return nameMatch && idMatch;
                        }
                    });
                });

                const appliedIds = new Set(
                    [...itemsToApply].map((item) => item.id)
                );

                // Check if all and none options should be updated
                const {
                    noneId,
                    allItemIds,
                    allNonSpecialItemIds,
                    noSpecialValue,
                    isNoneSpecial,
                } = this.extractFilterSelectionData();

                // Analyze current and previous selection state
                const { shouldSelectAll, shouldDeselectAll } =
                    this.analyzeSelectionChange(
                        appliedIds,
                        allNonSpecialItemIds,
                        noSpecialValue
                    );

                // Apply appropriate selection action
                if (shouldSelectAll) {
                    this.selectAllOptions(allItemIds, isNoneSpecial, noneId);
                } else if (shouldDeselectAll) {
                    this.deselectAllOptions({ noneId, isNoneSpecial });
                } else {
                    this.selectedOptionIds.set(appliedIds);
                }

                // the above handles setting of selectedOptionIds
                // need to update the appliedAndSelectedIds to be the same
                this.appliedAndSelectedOptionIds.set(
                    new Set(this.selectedOptionIds())
                );
            });
    }

    private onSearch(): void {
        // in this instance, all and none are outside the realm of the filters
        // and always appear listed
        const noSpecialValue = (id: U): boolean => {
            const allId = this.allOption()?.id;
            const noneId = this.noneOption()?.id;
            return id !== allId && id !== noneId;
        };

        this.search$
            .pipe(
                validateSearchInput({ debounce: DEBOUNCE_DELAY.Instant }),
                map((value) => {
                    if (!value.length) {
                        return null;
                    }
                    const optionsWithoutSpecialValues =
                        this.filter().options.filter((option) =>
                            noSpecialValue(option.id)
                        );
                    const filteredOptions = optionsWithoutSpecialValues.filter(
                        (option) =>
                            option.name
                                .toLowerCase()
                                .includes(value.toLowerCase())
                    );
                    return filteredOptions;
                }),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((response) => {
                if (!response) {
                    const options = this.filter().options.filter((option) =>
                        noSpecialValue(option.id)
                    );

                    this.filteredOptions.set([...options]);
                    return;
                }
                this.filteredOptions.set(response);
            });
    }

    protected clearSearch() {
        this.search.set('');
    }

    private forcePanelToMatchTriggerWidth() {
        const menuTrigger = this.menuTriggerRef();
        menuTrigger?.menuOpened
            .pipe(
                filter(() => this.panelWidth() === 'match'),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(() => {
                const triggerElement = this.menuTriggerEl()?.nativeElement;
                if (!(triggerElement instanceof HTMLButtonElement)) return;

                const width = `${triggerElement.offsetWidth}px`;
                const panelId = menuTrigger.menu?.panelId;
                const selector = panelId
                    ? `#${panelId}.filter-menu`
                    : '.filter-menu';

                const menuPanel = document.querySelector(selector);

                if (menuPanel instanceof HTMLElement) {
                    menuPanel.style.setProperty(
                        '--filter-trigger-width',
                        width
                    );
                }
            });
    }

    protected isOptionSelected(id: U): boolean {
        return this.selectedOptionIds().has(id);
    }

    protected onToggleAll(checked: boolean): void {
        const { noneId, allItemIds, isNoneSpecial } =
            this.extractFilterSelectionData();

        if (checked) {
            this.selectAllOptions(allItemIds, isNoneSpecial, noneId);
        } else {
            this.deselectAllOptions({ noneId, isNoneSpecial });
        }
    }

    protected onToggleNone(checked: boolean): void {
        const { noneId, allItemIds, isNoneSpecial } =
            this.extractFilterSelectionData();

        if (noneId !== undefined && !isNoneSpecial) {
            this.onToggleOrdinary(noneId, checked);
            return;
        }

        if (checked) {
            this.deselectAllOptions({ noneId, isNoneSpecial });
        } else {
            this.selectAllOptions(allItemIds, isNoneSpecial, noneId);
        }
    }

    protected onToggleOrdinary(optionId: U, checked: boolean): void {
        const newSelectedIds = new Set(this.selectedOptionIds());

        if (checked) {
            newSelectedIds.add(optionId);
        } else {
            newSelectedIds.delete(optionId);
        }

        const {
            noneId,
            allItemIds,
            allNonSpecialItemIds,
            noSpecialValue,
            isNoneSpecial,
        } = this.extractFilterSelectionData();

        // Analyze current and previous selection state
        const {
            shouldSelectAll,
            shouldDeselectAll,
            shouldRemoveAllOrNoneOption,
        } = this.analyzeSelectionChange(
            newSelectedIds,
            allNonSpecialItemIds,
            noSpecialValue
        );

        // Apply appropriate selection action
        if (shouldSelectAll) {
            this.selectAllOptions(allItemIds, isNoneSpecial, noneId);
        } else if (shouldDeselectAll) {
            this.deselectAllOptions({ noneId, isNoneSpecial });
        } else if (shouldRemoveAllOrNoneOption) {
            this.updateSelectionWithoutSpecialOptions(
                newSelectedIds,
                noSpecialValue
            );
        } else {
            this.selectedOptionIds.set(new Set(newSelectedIds));
        }
    }

    private extractFilterSelectionData() {
        const allId = this.allOption()?.id;
        const noneId = this.noneOption()?.id;
        const allItemIds = [...this.filter().options].map(
            (option) => option.id
        );

        if (allId !== undefined) {
            allItemIds.push(allId);
        }

        if (noneId !== undefined) {
            allItemIds.push(noneId);
        }

        const isNoneSpecial = this.isNoneOptionSpecial();

        const noSpecialValue = (id: U) =>
            id !== allId && (!isNoneSpecial || id !== noneId);

        const allNonSpecialItemIds = allItemIds.filter(noSpecialValue);

        return {
            allItemIds,
            allNonSpecialItemIds,
            allId,
            noneId,
            noSpecialValue,
            isNoneSpecial,
        };
    }

    private analyzeSelectionChange(
        rawSet: Set<U>,
        allNonSpecialItems: U[],
        noSpecialValue: (id: U) => boolean
    ) {
        // Extract IDs from newly selected values
        const newValueIds = [...rawSet];
        const prevValueIds = [...this.selectedOptionIds()];

        // Filter out special values option for specific checks
        const newNonSpecialValueIds = new Set(
            [...newValueIds].filter(noSpecialValue)
        );
        const prevNonSpecialValueIds = new Set(
            [...prevValueIds].filter(noSpecialValue)
        );

        const hasOptionsSelected = !!newNonSpecialValueIds.size;

        const toggleAllOnByOtherOptions =
            hasOptionsSelected &&
            newNonSpecialValueIds.size === allNonSpecialItems.length &&
            prevNonSpecialValueIds.size !== allNonSpecialItems.length;

        const toggleAllOffByOtherOptions =
            newNonSpecialValueIds.size !== allNonSpecialItems.length &&
            prevNonSpecialValueIds.size === allNonSpecialItems.length;

        const toggleNoneOnByOtherOptions = !hasOptionsSelected;

        const toggleNoneOffByOtherOptions =
            hasOptionsSelected &&
            newNonSpecialValueIds.size === 1 &&
            prevNonSpecialValueIds.size === 0;

        return {
            shouldSelectAll: toggleAllOnByOtherOptions,
            shouldDeselectAll: toggleNoneOnByOtherOptions,
            shouldRemoveAllOrNoneOption:
                toggleAllOffByOtherOptions || toggleNoneOffByOtherOptions,
        };
    }

    private selectAllOptions(
        allItemIds: U[],
        isNoneSpecial: boolean,
        noneId: U | undefined
    ) {
        const noSpecialNoneValue = (id: U) => !isNoneSpecial || id !== noneId;
        const allItemsWithoutSpecialNoneIds =
            allItemIds.filter(noSpecialNoneValue);
        this.selectedOptionIds.set(new Set(allItemsWithoutSpecialNoneIds));
    }

    /** special options includes ALL and maybe NONE */
    private updateSelectionWithoutSpecialOptions(
        rawSet: Set<U>,
        noSpecialValue: (id: U) => boolean
    ) {
        const selectedIds = [...rawSet].filter(noSpecialValue);
        this.selectedOptionIds.set(new Set(selectedIds));
    }

    private deselectAllOptions({
        noneId,
        isNoneSpecial,
    }: {
        noneId: U | undefined;
        isNoneSpecial: boolean;
    }): void {
        // if there is a "None" option AND it's treated as special, then select it
        if (noneId !== undefined && isNoneSpecial) {
            this.selectedOptionIds.set(new Set([noneId]));
        } else {
            // Otherwise, just clear the selection
            this.selectedOptionIds.set(new Set());
        }
    }

    protected clearAll() {
        const { noneId, isNoneSpecial } = this.extractFilterSelectionData();
        this.deselectAllOptions({ noneId, isNoneSpecial });
    }

    /**
     * Handles the opening and closing events of the filter dropdown.
     * When closing without applying, restores the previously applied filter state.
     *
     * @param open - Boolean indicating if the dropdown is being opened (true) or closed (false)
     *
     * @remarks
     * - Acts as a cancellation mechanism when closing without applying changes
     * - Copies the last confirmed selection state from appliedAndSelectedFilterItems back to selectedFilterItems
     * - Only triggers restoration logic when the dropdown is closing (open === false)
     * ```
     */
    protected onDropdownClosed(): void {
        if (this.applyOnClose()) {
            this.applyFilters();
        } else {
            // set selected items as the applied and selected items
            const appliedItems = this.appliedAndSelectedOptionIds();
            this.selectedOptionIds.set(new Set(appliedItems));
        }
    }

    /**
     * Applies the current filter selection state and emits the selected items.
     * Creates a new copy of selections to prevent mutation issues.
     *
     * @emits appliedChange - Emits array of selected filter item ids
     *
     * @remarks
     * - Updates appliedAndSelectedFilterItems with current selections
     * - Creates new array references using spread operator to prevent mutations
     * - Emits selected items through appliedChange output
     * - Automatically closes the dropdown after applying
     */
    private applyFilters(): void {
        const itemsToBeApplied = [...this.selectedOptionIds()];
        this.appliedAndSelectedOptionIds.set(new Set(itemsToBeApplied));

        const returnAllOption = (id: U) =>
            this.returnAllOption() ? true : id !== this.allOption()?.id;
        const returnNoneOption = (id: U) =>
            !this.isNoneOptionSpecial() || this.returnNoneOption()
                ? true
                : id !== this.noneOption()?.id;

        const appliedIds: AppliedFilterOptionId<U>[] = itemsToBeApplied.filter(
            (id) => returnAllOption(id) && returnNoneOption(id)
        );
        this.appliedChange.emit(appliedIds);
    }

    protected applyFiltersAndCloseDropdown() {
        this.applyFilters();
        this.menuTriggerRef()?.closeMenu();
    }

    protected compareItemsFn(item1: FilterOption<U>, item2: FilterOption<U>) {
        return item1 && item2 ? item1.id === item2.id : item1 === item2;
    }
}
