import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import {
    DocumentApiService,
    FileService,
    IDuplicateDocRequest,
} from '@quipex/shared/data';
import { DndDirective } from '@quipex/shared/directives';
import { DuplicatesModalComponent } from '../duplicates-modal/duplicates-modal.component';
import { DuplicatesModalService } from '../duplicates-modal/duplicates-modal.service';

@Component({
    selector: 'qpx-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        DndDirective,
    ],
})
export class FileUploadComponent {
    @Input() buildingId!: string;
    @Input() shouldCheckForDuplicates = true;
    @Input() multiple = 'multiple';
    @Input() withDragAndDrop = true;
    @Input() buttonType = true;
    @Input() allowFolderSelection = false;
    @Input() allowBothFolderAndFileSelection = false;

    @Input() set isLoading(value: boolean) {
        this.uploadEnabled = !value;
    }

    @ViewChild('fileInput') fileInput!: ElementRef;

    fileListChange = output<File[]>();
    processingChange = output<boolean>();

    uploadEnabled = true;

    constructor(
        private matDialog: MatDialog,
        private documentService: DocumentApiService,
        private fileService: FileService,
        private duplicatesModalService: DuplicatesModalService
    ) {
        this.processingChange.emit(false);
    }

    openFileBrowser(): void {
        this.fileInput.nativeElement.click();
    }

    onFileInputClick(event: any): void {
        // needed for enabling same file upload
        event.target.value = null;
    }
    /**
     * on file drop handler
     */
    async onFileDropped($event: any): Promise<void> {
        if (!$event?.length && !$event?.dataTransfer?.items) {
            return;
        }

        this.uploadEnabled = false;
        this.processingChange.emit(true);

        let files: File[] = [];

        // Handle folder drop via DataTransferItemList
        if ($event?.dataTransfer?.items) {
            const items = Array.from($event.dataTransfer.items);
            const filePromises: Promise<File[]>[] = items.map(
                async (item: any) => {
                    if (item.webkitGetAsEntry) {
                        const entry = item.webkitGetAsEntry();
                        if (entry?.isDirectory) {
                            return this.getAllFilesFromDirectory(entry);
                        } else if (entry?.isFile) {
                            return new Promise<File[]>((resolve) => {
                                entry.file((file: File) => resolve([file]));
                            });
                        }
                    }
                    return [];
                }
            );

            const fileArrays = await Promise.all(filePromises);
            files = fileArrays.flat();
        } else {
            // Handle regular file drop
            files = [...Array.from($event)] as File[];
        }

        files = this.fileService.validateFiles(files);

        if (files.length) {
            if (this.shouldCheckForDuplicates) {
                this.checkDuplicateDocument(files);
            } else {
                this.fileListChange.emit(files);
                this.uploadEnabled = true;
            }
        } else {
            this.uploadEnabled = true;
            this.processingChange.emit(false);
        }
    }

    /**
     * Recursively get all files from a directory
     */
    private async getAllFilesFromDirectory(
        directoryEntry: any
    ): Promise<File[]> {
        const files: File[] = [];
        const reader = directoryEntry.createReader();

        const entries = await new Promise<any[]>((resolve) => {
            reader.readEntries((entries: any[]) => resolve(entries));
        });

        for (const entry of entries) {
            if (entry.isDirectory) {
                const subFiles = await this.getAllFilesFromDirectory(entry);
                files.push(...subFiles);
            } else {
                const file = await new Promise<File>((resolve) => {
                    entry.file((file: File) => resolve(file));
                });
                files.push(file);
            }
        }

        return files;
    }

    /**
     * handle file from browsing
     */
    async fileBrowseHandler($event: any) {
        const fileList: FileList = $event.target.files;
        if (!fileList?.length) {
            return;
        }

        let files: File[] = [];

        // Handle folder selection
        if (this.allowFolderSelection) {
            files = Array.from(fileList).filter(
                (item): item is File => item instanceof File
            );
        } else {
            files = [...Array.from(fileList)];
        }

        this.uploadEnabled = false;
        this.processingChange.emit(true);

        files = this.fileService.validateFiles(files);

        if (files.length) {
            if (this.shouldCheckForDuplicates) {
                this.checkDuplicateDocument(files);
            } else {
                this.uploadEnabled = true;
                this.fileListChange.emit(files);
            }
        } else {
            this.uploadEnabled = true;
            this.processingChange.emit(false);
        }
    }

    private checkDuplicateDocument(files: File[]): void {
        const request: IDuplicateDocRequest = {
            filesInfo: files.map((file) => ({
                size: file.size,
                name: file.name,
            })),
            buildingGuid: this.buildingId,
        };

        this.documentService.checkDuplicateDocument(request).subscribe({
            next: (result: string[]) => {
                this.processingChange.emit(false);

                if (result?.length) {
                    this.showDuplicatesModal(result, files);
                } else {
                    this.uploadEnabled = true;
                    this.fileListChange.emit(files);
                }
            },
            error: () => {
                this.processingChange.emit(false);
                this.uploadEnabled = true;
            },
        });
    }

    private showDuplicatesModal(duplicates: string[], files: File[]): void {
        const dilogRef = this.matDialog.open(DuplicatesModalComponent, {
            data: {
                duplicates: duplicates,
            },
            panelClass: ['confirm-dialog'],
            disableClose: true,
        });

        this.duplicatesModalService.setDialogVisible(true);

        dilogRef.afterClosed().subscribe({
            next: (result) => {
                this.duplicatesModalService.setDialogVisible(false);
                this.processingChange.emit(false);
                this.uploadEnabled = true;

                if (result === 'confirm') {
                    this.fileListChange.emit(files);
                } else {
                    const noDuplicatedFiles: File[] = [];
                    Array.from(files)?.filter((file) => {
                        if (!duplicates.includes(file.name)) {
                            noDuplicatedFiles.push(file);
                        }
                    });
                    this.fileListChange.emit(noDuplicatedFiles);
                }
            },
        });
    }
}
