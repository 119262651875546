import {
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
    input,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';
import { map, of } from 'rxjs';

@Component({
    selector: 'qpx-building-manual',
    templateUrl: './building-manual.component.html',
    styleUrls: ['./building-manual.component.scss'],
    imports: [
        RouterLink,
        RouterLinkActive,
        MatInputModule,
        MatIconModule,
        MatProgressBarModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildingManualComponent {
    private readonly route = inject(ActivatedRoute);
    private readonly utilsService = inject(UtilsService);

    public readonly buildingManualPercentage = input.required<number>();
    public readonly missingKeyItemsNo = input.required<number>();
    public readonly totalKeyItemsNo = input.required<number>();
    public readonly completedItemsNo = input.required<number>();
    public readonly canUpdate = input.required<boolean>();

    private readonly buildingGuid$ =
        this.route.parent?.paramMap.pipe(
            map((params) => params.get('buildingGuid') ?? '')
        ) ?? of('');
    protected readonly buildingGuid = toSignal(this.buildingGuid$, {
        initialValue: '',
    });

    protected readonly progressValue = computed(() =>
        Math.ceil(
            this.utilsService.rangeToPercent(
                this.completedItemsNo(),
                0,
                this.totalKeyItemsNo()
            ) * 100
        )
    );
}
