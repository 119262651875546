import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, Validators } from '@angular/forms';
import { map, mergeWith, of, switchMap } from 'rxjs';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'label[mat-label]',
    template: `<ng-content />{{ _required() ? ' *' : '' }}`,
    host: {
        '[attr.for]': 'for()',
        class: 'qpx-label',
        '[attr.required]': '_required() ? "" : null',
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class QpxFormFieldLabel {
    public readonly for = input.required<string>();

    public readonly required = input<boolean | FormControl<unknown>>(false);
    private readonly required$ = toObservable(this.required).pipe(
        switchMap((required) => {
            if (typeof required === 'boolean') {
                return of(required);
            } else {
                return of(required).pipe(
                    mergeWith(required.valueChanges, required.statusChanges),
                    map(() => !!required.hasValidator(Validators.required))
                );
            }
        })
    );
    protected readonly _required = toSignal(this.required$);
}
