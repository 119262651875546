import { computed, Directive, input } from '@angular/core';
import { COLOR_CONTEXT, ColorContext } from '@quipex/shared/helpers';

@Directive({
    selector: '[qpxBody]',
    host: {
        '[class]': 'className()',
    },
})
export class QpxBodyDirective {
    public readonly variant = input<ColorContext, ColorContext | ''>(
        COLOR_CONTEXT.Primary,
        {
            alias: 'qpxBody',
            transform: (value: ColorContext | '') =>
                value || COLOR_CONTEXT.Primary,
        }
    );

    protected readonly className = computed(() => {
        return ['qpx-font-body', 'm-0', `text-${this.variant()}`].join(' ');
    });
}
