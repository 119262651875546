import { pipe } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
} from 'rxjs/operators';

export const DEFAULT_MINIMUM_SEARCH_CHARACTERS = 3;

export const DEBOUNCE_DELAY = {
    None: 0,
    /** Very fast, best for local filtering (high API request risk) */
    Instant: 100,
    /** Slightly delayed, good for near-instant search feedback */
    Fast: 150,
    /** A balance between responsiveness and request reduction */
    Balanced: 200,
    /** Standard debounce for search inputs and API calls */
    Standard: 300,
    /** Reduces API calls further, slight input delay */
    Conservative: 400,
    /** Best for expensive operations or reducing server load */
    Slow: 500,
} as const;

export type DebounceDelay =
    (typeof DEBOUNCE_DELAY)[keyof typeof DEBOUNCE_DELAY];

export interface ValidateSearchPipeConfig {
    /** in milliseconds */
    debounce?: DebounceDelay | number;
}

/** debounce and trim the search input */
export const validateSearchInput = (config: ValidateSearchPipeConfig = {}) => {
    const { debounce = DEBOUNCE_DELAY.Fast } = config;

    return pipe(
        debounceTime(debounce),
        distinctUntilChanged(),
        filter((value: unknown): value is string => typeof value === 'string'),
        map((value) => value.trim())
    );
};

/**
 * Allow non-empty search term or empty terms after the initial render
 * this ensures we don't trigger a search on component initialization with empty string
 */
export function skipInitialEmptySearch() {
    return pipe(
        filter((value: string, index: number) => {
            // Allow non-empty values or empty values after the initial emission
            return value.length > 0 || index > 0;
        })
    );
}
