import { Pipe, type PipeTransform } from '@angular/core';
import {
    toAddressFormat,
    AddressMode,
    ADDRESS_MODE,
} from '@quipex/shared/helpers';

@Pipe({
    name: 'qpxAddress',
})
export class AddressPipe implements PipeTransform {
    transform(value: unknown, mode: AddressMode = ADDRESS_MODE.Full): string {
        return toAddressFormat(value, mode);
    }
}
