import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'qpx-loader',
    template: `<mat-spinner
        [attr.aria-label]="ariaLabel() || null"
        [attr.aria-labelledby]="ariaLabelledBy() || null"
        [color]="color()"
        class="qpx-loader"
        [ngClass]="inputClasses()"
    ></mat-spinner>`,
    imports: [NgClass, MatProgressSpinnerModule],
    host: {
        '[class]': 'containerClass()',
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
    public readonly ariaLabel = input<string>('');
    public readonly ariaLabelledBy = input<string>('');
    public readonly inputClasses = input<string[]>([]);
    public readonly color = input<'primary' | 'accent'>('accent');
    public readonly containerClass = input('load-center-absolute');
}
